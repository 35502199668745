import styles from "./BotFlowQuestionContent.module.css"
import Botimage from "../../../../Img/Image1.png";
import user from '../../../../Img/user.png';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { MdOutlineAttachment } from "react-icons/md";

import TextField from '@mui/material/TextField';
import "bootstrap/dist/css/bootstrap.min.css";
import { useGlobalVariablesContext } from "../../../../Service/GlobalVariables";

export default function BotFlowQuestionContent({ selectedCardIndex, cardList, handleBottomSelectChange2,
    handleOpen, open, handleClose, choices, setChoices, handleSave, selectedCard,startingCard,setStartingCard,startingSelectedCardIndex }) {

    const { updateCardList } = useGlobalVariablesContext();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        height: 500,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleQuestionChange = (index, value) => {
        const updatedCardList = [...cardList];
        updatedCardList[index].content = value;
        updateCardList(updatedCardList);
    };

    const handleStartingChange = (index, value) => {
        const updatedCardList = [...startingCard];
        updatedCardList[index].content = value;
        setStartingCard(updatedCardList);
    };

    const handleMcqOptionChange = (index, optionIndex, value) => {


        const updatedCardList = [...cardList];

        const oldValue = updatedCardList[index].options[optionIndex]
        updatedCardList[index].options[optionIndex] = value;

        if (updatedCardList[index].logic) {
            updatedCardList[index].logic.forEach(item => {
                if (item.option === oldValue) {
                    item.option = value;
                }
            });
        }

        updateCardList(updatedCardList);
    };

    return (
        <div className={styles['AI-main-content']}>
            {selectedCard && selectedCard[0] && selectedCard[0].questionType === "Multiple Choice" && (
                <div className={styles['short-text-form']}>
                    <div className={styles["centered-container-shortText"]}>
                        {/* Bot's image and name */}
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <p style={{ marginBottom: '4px', fontSize: '16px', color: '223952', fontWeight: "500" }}>Multiple Choice Question</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={Botimage} className={styles['user-img']} />
                            </div>
                            <div style={{ width: "90%" }}>
                                <p style={{ marginLeft: '10px', marginBottom: '4px', fontSize: '12px', color: "black" }}>Bot</p>
                                <input
                                    type='text'
                                    className={styles['chat-bubble-bot']}
                                    placeholder='Type your Question here'
                                    value={cardList[selectedCardIndex].content}
                                    onChange={(e) => handleQuestionChange(selectedCardIndex, e.target.value)}
                                />
                            </div>
                        </div>

                        <div style={{ width: "80%", marginLeft: "26px" }}>
                            <form className={styles['mcq-form']}>
                                {cardList[selectedCardIndex].options.map((option, optionIndex) => (
                                    (
                                        <div className={`${styles["mcq-option"]} m-2`} key={optionIndex}>
                                            <span className={styles["mcq-label"]}>{String.fromCharCode(65 + optionIndex)}</span>
                                            <input
                                                type="text"
                                                placeholder={`Option ${String.fromCharCode(65 + optionIndex)}`}
                                                className={styles["mcq-input"]}
                                                value={option}
                                                onChange={(e) => handleMcqOptionChange(selectedCardIndex, optionIndex, e.target.value)}
                                            />
                                        </div>
                                    )
                                ))}
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {selectedCard && selectedCard[0] && selectedCard[0].questionType === "Short Text" && (

                <div className={styles['short-text-form']}>

                    <div className={styles["centered-container-shortText"]}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <p style={{ marginBottom: '4px', fontSize: '16px', color: '223952', fontWeight: "500" }}>Short Question</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={Botimage} className={styles['user-img']} />
                            </div>
                            <div style={{ width: "90%" }}>
                                <p style={{ marginLeft: '10px', marginBottom: '4px', fontSize: '12px', color: "black" }}>Bot</p>
                                <textarea value={cardList[selectedCardIndex].content} type='text' className={styles['chat-bubble-bot']} placeholder='Type your question here' onChange={(e) => handleQuestionChange(selectedCardIndex, e.target.value)} />
                            </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <p className={styles['chat-bubble-user']}>Input field  (Answer)</p>
                            <img src={user} className={styles['user-img']} />
                        </div>

                    </div>


                </div>

            )}


            {selectedCard && selectedCard[0] && selectedCard[0].questionType === "Attachment" && (

                <div className={styles['short-text-form']}>

                    <div className={styles["centered-container-shortText"]}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <p style={{ marginBottom: '4px', fontSize: '16px', color: '223952', fontWeight: "500" }}>Attachment</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={Botimage} className={styles['user-img']} />
                            </div>
                            <div style={{ width: "90%" }}>
                                <p style={{ marginLeft: '10px', marginBottom: '4px', fontSize: '12px', color: "black" }}>Bot</p>
                                <textarea value={cardList[selectedCardIndex].content} type='text' className={styles['chat-bubble-bot']} placeholder='Type your question here' onChange={(e) => handleQuestionChange(selectedCardIndex, e.target.value)} />
                            </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <p className={styles['chat-bubble-user']}>
                                <MdOutlineAttachment style={{ fontSize: "17px" }} />

                                Attachment
                            </p>
                            <img src={user} className={styles['user-img']} />
                        </div>

                    </div>


                </div>

            )}


            {selectedCard && selectedCard[0] && selectedCard[0].questionType === "Statement" && (

                <div className={styles['short-text-form']}>

                    <div className={styles["centered-container-shortText"]}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <p style={{ marginBottom: '4px', fontSize: '16px', color: '223952', fontWeight: "500" }}>Statement</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={Botimage} className={styles['user-img']} />
                            </div>
                            <div style={{ width: "90%" }}>
                                <p style={{ marginLeft: '10px', marginBottom: '4px', fontSize: '12px', color: "black" }}>Bot</p>
                                <textarea value={cardList[selectedCardIndex].content} type='text' className={styles['chat-bubble-bot']} placeholder='Type your Message here' onChange={(e) => handleQuestionChange(selectedCardIndex, e.target.value)} />
                            </div>
                        </div>

                    </div>


                </div>

            )}

            {selectedCard && selectedCard[0] && selectedCard[0].questionType === "Dropdown" && (


                <div className={styles['short-text-form']}>

                    <div className={styles["centered-container-shortText"]}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <p style={{ marginBottom: '4px', fontSize: '16px', color: '223952', fontWeight: "500" }}>Dropdown</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            {/* Bot section */}
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={Botimage} className={styles['user-img']} alt="Bot" />
                            </div>
                            <div style={{ width: "90%" }}>
                                <p style={{ marginLeft: '10px', marginBottom: '4px', fontSize: '12px', color: "black" }}>Bot</p>
                                <textarea
                                    type='text'
                                    className={styles['chat-bubble-bot']}
                                    placeholder='Type your Question here'
                                    value={cardList[selectedCardIndex].content}
                                    onChange={(e) => handleQuestionChange(selectedCardIndex, e.target.value)}
                                />
                            </div>
                        </div>
                        {/* User section */}
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <p className={styles['chat-bubble-user']}>
                                <select
                                    id="bottom-dropdown"
                                    className={styles.bottomDropdown}
                                    value={cardList[selectedCardIndex].options}
                                    onChange={handleBottomSelectChange2}
                                    disabled={!cardList[selectedCardIndex].options || cardList[selectedCardIndex].options.length === 0}
                                >
                                    {cardList[selectedCardIndex].options && cardList[selectedCardIndex].options.length > 0 ? (
                                        <>
                                            <option value=""> Select an option</option>
                                            {cardList[selectedCardIndex].options.map((option, index) => (
                                                <option key={index} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </>
                                    ) : (
                                        <option value=""> No options</option>
                                    )}
                                </select>

                            </p>



                            <img src={user} className={styles['user-img']} alt="User" />

                        </div>
                        <div className={styles['edit-option-button-div']}>
                            <button className={styles["edit-button"]} onClick={() => handleOpen(cardList[selectedCardIndex].options)}  >
                                Edit Options
                            </button>
                        </div>
                    </div>
                    {/* Modal for editing options */}

                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                    >
                        <Fade in={open}>
                            <Box sx={style}>
                                <Typography id="transition-modal-title" variant="h6" component="h2">
                                    Edit Choices
                                </Typography>
                                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                    Write or paste your choices below. Each choice must be on a separate line.
                                </Typography>
                                <TextField
                                    multiline
                                    rows={4}
                                    fullWidth
                                    inputProps={{
                                        style: { height: '250px' },
                                    }}
                                    variant="outlined"
                                    placeholder="Enter your choices here..."
                                    value={choices}
                                    onChange={(e) => setChoices(e.target.value)}
                                />
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                                    <Button variant="contained" color="primary" onClick={() => handleSave(selectedCardIndex)} >
                                        Save Choices
                                    </Button>
                                    <Button variant="outlined" color="secondary" onClick={handleClose} style={{ marginLeft: '1rem' }}>
                                        Exit
                                    </Button>
                                </div>
                            </Box>
                        </Fade>
                    </Modal>

                </div>

            )}


            {selectedCard && selectedCard[0] && selectedCard[0].questionType === "Email" && (

                <div className={styles['short-text-form']}>

                    <div className={styles["centered-container-shortText"]}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <p style={{ marginBottom: '4px', fontSize: '16px', color: '223952', fontWeight: "500" }}>Email</p>
                        </div>

                        <div style={{ display: "flex" }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={Botimage} className={styles['user-img']} />
                            </div>
                            <div style={{ width: "90%" }}>
                                <p style={{ marginLeft: '10px', marginBottom: '4px', fontSize: '12px', color: "black" }}>Bot</p>
                                <textarea value={cardList[selectedCardIndex].content} type='text' className={styles['chat-bubble-bot']} placeholder='Type your question here' onChange={(e) => handleQuestionChange(selectedCardIndex, e.target.value)} />
                            </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <p className={styles['chat-bubble-user']}>Name@example.com</p>
                            <img src={user} className={styles['user-img']} />
                        </div>

                    </div>


                </div>

            )}



            {selectedCard && selectedCard[0] && selectedCard[0].questionType === "Starting" && (

                <div className={styles['short-text-form']}>
                    <div className={styles["centered-container-shortText"]}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <p style={{ marginBottom: '4px', fontSize: '16px', color: '223952', fontWeight: "500" }}>Starting</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={Botimage} className={styles['user-img']} />
                            </div>
                            <div style={{ width: "90%" }}>
                                <p style={{ marginLeft: '10px', marginBottom: '4px', fontSize: '12px', color: "black" }}>Bot</p>
                                <textarea value={startingCard[startingSelectedCardIndex].content} type='text' className={styles['chat-bubble-bot']} placeholder='Type your question here' onChange={(e) => handleStartingChange(startingSelectedCardIndex, e.target.value)} />
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <p className={styles['chat-bubble-user']}>
                               Input field (Answer)
                            </p>
                            <img src={user} className={styles['user-img']} />
                        </div>

                    </div>


                </div>

            )}




        </div>

    )
}