import { Avatar, Tooltip } from "@mui/material";
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { GrAttachment } from "react-icons/gr";
import styles from "./conversationBox.module.css"
import Header from "../Header/Header";
import socket from "../../../../socketjs";
import { BiLockOpenAlt } from "react-icons/bi";
import { BsFillSendFill } from "react-icons/bs";
import { IoCloseOutline } from "react-icons/io5";
import { useUser } from "../../../../Service/SelectedUserContext";
import { BsDot } from "react-icons/bs";
import { IoMdClose, IoMdDownload } from "react-icons/io";
import PdfIcon from "./icon/PdfIcon";
import PDFViewer from "../../../../Components/PDFViewer/PDFViewer"
import { IoChatboxOutline } from "react-icons/io5";
import { CiLogout } from "react-icons/ci";
import { CiLock } from "react-icons/ci";
import { IoIosClose } from "react-icons/io";
import pdfImage from "../../../../Img/pdf.png"
import { MdOutlineAttachment } from "react-icons/md";
<MdOutlineAttachment />


export default function ConversationBox({ isLoading, messages, username, currentAuthUsername, selectedUserSessionId, messageSend,
    onMessage, setChatState, chatState, Isconversation, setMessageSend, handleFileChange, selectedUserId, SelectedPreview, selectedFileName, handleIconClick, messageEndRef, messageContent, setMessageContent, props, selectedPdfPreview, selectedFile, selectedPdfUrl }) {
    console.log("Selected File in box : ", selectedFile)
    

    const [pdfPreview, setPdfPreview] = useState("")
    console.log("pdfPreview : ", pdfPreview)
    const messageContainerRef = useRef(null);
    const [isTyping, setIsTyping] = useState(false);
    const [imagePreview, setImagePreview] = useState("")
    const [UserTypingContent, setUserTypingContent] = useState('');
    const [fromUser, setFromUser] = useState('');
    const { selectedUser } = useUser();
    let ref;
    let CurrentdateStatus;
    let typingTimer;

    const handleUserTyping = useCallback(({ messageContent, from }) => {
        setMessageSend(false);
        setFromUser(from)
        if (from === selectedUser.sessionID) {
            setUserTypingContent(messageContent);
            clearTimeout(typingTimer);
            setIsTyping(true);
            setUserTypingContent(messageContent);
            typingTimer = setTimeout(() => {
                setIsTyping(false);
                setUserTypingContent('');
            }, 3000);
        }
    }, [selectedUser]); // Include selectedUser in the dependency array

    useEffect(() => {
        socket.on('userTyping', handleUserTyping);
        return () => {
            socket.off('userTyping', handleUserTyping);
            clearTimeout(typingTimer);
        };
    }, [handleUserTyping]);

    const handleImagePrev = (fileUrl) => {
        setImagePreview(fileUrl)
        console.log("handleImagePrev : ", fileUrl)
    }

    useEffect(() => {
        setIsTyping(false);
        setUserTypingContent('');
    }, [selectedUserSessionId]);


    function getDateHeader(timestamp) {
        const messageDate = new Date(timestamp);
        const currentDate = new Date();
        const isToday = messageDate.toLocaleDateString('en-US', { timeZone: 'America/New_York' }) === currentDate.toLocaleDateString('en-US', { timeZone: 'America/New_York' });
        const isYesterday =
            new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate() - 1
            ).toLocaleDateString('en-US', { timeZone: 'America/New_York' }) === messageDate.toLocaleDateString('en-US', { timeZone: 'America/New_York' });
        let dateHeader = null;
    
        if (isToday) {
            dateHeader = 'Today';
        } else if (isYesterday) {
            dateHeader = 'Yesterday';
        } else {
            const options = { day: 'numeric', month: 'long', timeZone: 'America/New_York' };
            dateHeader = messageDate.toLocaleDateString('en-US', options);
        }
    
        return dateHeader;
    }
    

    function formatFileName(fileName) {
        const maxLength = 15; // Maximum length before truncation
        const extension = fileName.split('.').pop(); // Get the file extension
        let formattedName = fileName;

        if (fileName.length > maxLength) {
            const truncatedName = fileName.substring(0, maxLength - 3); // Subtracting 3 for "..."
            formattedName = `${truncatedName}...${extension}`;
        }

        return formattedName;
    }


    function formatTime(timestamp) {
        const date = new Date(timestamp);
        let hours = date.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours %= 12;
        hours = hours || 12;
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes} ${ampm}`;
    }

    const getContent = (e) => {
        setMessageContent(e.target.value)
        ref = e;

        if (messageContent.trim().length > 0) {
            socket.emit('admin_typing', {
                to: props.selectedUser.sessionID,
                username: props.selectedUser.username,
                from: props.CurrentusersessionID,
                messageContent: messageContent
            });
        }
    };

    const handleDownload = (pdfUrl) => {
        window.open(pdfUrl, '_blank');
    };
    const renderPdfAttachment = (fileUrl, usergroup, fileName) => {

        return <div style={{
            backgroundColor: usergroup === "admin" ? "#223952" : "white",
            borderBottom: usergroup !== "admin" ? "1px solid #ebebeb" : "none"
        }}
            className={styles.for_pdf}
            onClick={() => setPdfPreview(fileUrl)}
        >
            <div className={styles.pdf_container}>
                <PdfIcon width="35px" height="40px" />
                <div className={styles.pdf_details_container}>
                    <p style={{ fontSize: "15px", margin: "0" }}>{fileName}</p>
                    <div className={styles.pdf_size}>
                        <p style={{ margin: "0" }}>PDF</p>
                        <BsDot width={20} height={20} />
                        <p style={{ margin: "0" }}>2MB</p>
                    </div>
                </div>
                <IoMdDownload onClick={() => handleDownload(fileUrl)} className={styles.download_icon} />
            </div>
        </div>
    };

    const renderImageAttachment = (fileUrl, usergroup) => (
        <div large={fileUrl} onClick={() => handleImagePrev(fileUrl)} style={{ padding: "0.1rem 0.2rem", backgroundColor: usergroup === "admin" ? "#223952" : "white", borderTopLeftRadius: "8px", borderTopRightRadius: "8px", width: "230px", height: "auto" }}>
            <img src={fileUrl} className={styles['image-attachment']} alt='message-data' />
        </div>
    );



    function formatWelcomeMessage(template) {
        if (template === null || template === undefined) {
            return "Error: Template is null or undefined";
        }

        

        const currentHour = new Date().getHours();
        
        const greeting = getGreeting(currentHour);
        const timeOfDay = getTimeOfDay(currentHour);
        
        return template.replace("[Greetings]", greeting).replace("[CurrentTime]", timeOfDay);
    }
    
    function getGreeting(currentHour) {
        if (currentHour >= 6 && currentHour < 12) {
            return "Good Morning";
        } else if (currentHour >= 12 && currentHour < 18) {
            return "Good Afternoon";
        } else if (currentHour >= 18 && currentHour < 22) {
            return "Good Evening";
        } else {
            return "I hope you had a good day";
        }
    }
    
    function getTimeOfDay(currentHour) {
        if (currentHour >= 6 && currentHour < 12) {
            return "today";
        } else if (currentHour >= 12 && currentHour < 18) {
            return "this afternoon";
        } else if (currentHour >= 18 && currentHour < 22) {
            return "today";
        } else {
            return "today";
        }
    }



    console.log("message list...",messages)

    const showMessages = messages.map((message, index) => {
        const dateHeader = getDateHeader(message.timestamp);
        let messageElement = null;
        let fileUrl = null
        let fileType = null
        let fileName = null

        if (message.fileDataLive && message.fileDataLive.fileType) {
            fileType = message.fileDataLive.fileType;
            fileUrl = message.fileDataLive.previewUrl;
            fileName = formatFileName(message.fileDataLive.fileName)
        }

        if (message.fileData && message.fileData[0] && message.fileData[0].fileType) {
            fileUrl = message.fileData[0].fileLink
            fileType = message.fileData[0].fileType
            fileName = formatFileName(message.fileData[0].fileName)


        }

        let greetingMessage
        if(message.questionType == "Starting"){
            greetingMessage = formatWelcomeMessage(message.content)
        }

        

        if (message.from_Usergroup === "admin") {
            let prevSenderIdRealtime;
            let isConsecutive = false

            if (index !== 0) {
                prevSenderIdRealtime = messages[index - 1];

                if (prevSenderIdRealtime === undefined || prevSenderIdRealtime === null) {
                    isConsecutive = false;
                } else {
                    isConsecutive = prevSenderIdRealtime.from_Usergroup === message.from_Usergroup
                }
            }

            messageElement = (
                <div className={styles['owner-top']}>
                    <div className={styles.owner}>
                        {!isConsecutive && (
                            <span>
                                {message.from_Username === 'bot' ? 'Bot' : 'you'}
                            </span>
                        )}
                        {fileType && fileType.includes('image') && renderImageAttachment(fileUrl, message.from_Usergroup)}
                        {imagePreview &&
                            <div className={styles["preview-overlay"]}>
                                <div className={styles["preview-header"]}>
                                    {/* <span className={styles["close-button"]} onClick={() => setImagePreview("")}> */}
                                    <IoIosClose className={styles["close-button"]} onClick={() => setImagePreview("")} />
                                    {/* </span> */}
                                </div>
                                <div className={styles["preview-content"]}>
                                    <img src={imagePreview} alt="Full Preview" className={styles["full-preview"]} />
                                </div>
                            </div>
                        }
                        {fileType && fileType.includes('pdf') &&

                            renderPdfAttachment(fileUrl, message.from_Usergroup, fileName)
                        }

                        {/* pdf viewer */}
                        

                        <div className={(fileUrl || fileType) ? styles.agent2 : styles.agent} style={{ backgroundColor: (fileType && fileType.includes('pdf')) ? "null" : "#223952" }}>
                            <p>{message.content}</p>
                            <span className={styles.time}>{formatTime(message.timestamp)}</span>
                        </div>
                    </div>
                </div >
            );

        } else if ((message.from_Usergroup === "client")) {

            if (selectedUserSessionId === message.from) {
                let prevSenderIdRealtime;
                let isConsecutive = false;

                if (index !== 0) {
                    prevSenderIdRealtime = messages[index - 1];

                    if (prevSenderIdRealtime === undefined || prevSenderIdRealtime === null) {
                        isConsecutive = false;
                    } else {
                        isConsecutive = prevSenderIdRealtime.from_Usergroup === message.from_Usergroup;
                    }
                }




                messageElement = (
                    <div
                        className={message.fileData ? isConsecutive ? styles.isConsecutiveImageOutsider : styles['image-outsider'] : isConsecutive ? styles.isConsecutive : styles.outsider}
                    >
                        {!isConsecutive && (
                            <div className={styles['outside-user']}>
                                <Avatar sx={{ bgcolor: "#0167c6", width: "35px", height: "35px", boxShadow: "1px 1px 7px 0 rgba(101, 101, 101, 0.2), 1px 1px 7px 0 rgba(100, 100, 100, 0.19)" }}>{username.charAt(0).toUpperCase()}</Avatar>
                            </div>
                        )}

                        <div className={styles['user-outside']}>
                            {!isConsecutive && (
                                <span className={styles.sendername}>{username}</span>
                            )}

                            {fileType && fileType.includes('image') && renderImageAttachment(fileUrl, message.from_Usergroup)}
                            {imagePreview &&
                            <div className={styles["preview-overlay"]}>
                                <div className={styles["preview-header"]}>
                                    {/* <span className={styles["close-button"]} onClick={() => setImagePreview("")}> */}
                                    <IoIosClose className={styles["close-button"]} onClick={() => setImagePreview("")} />
                                    {/* </span> */}
                                </div>
                                <div className={styles["preview-content"]}>
                                    <img src={imagePreview} alt="Full Preview" className={styles["full-preview"]} />
                                </div>
                            </div>
                        }
                            {fileType && fileType.includes('pdf') && renderPdfAttachment(fileUrl, message.from_Usergroup)}


                            <div className={fileUrl ? styles['outside-user-message-with-image'] : styles['outside-user-message']}>
                                <p>{message.content}</p>
                                <span style={{ marginTop: "9px", alignSelf: "flex-start" }} className={styles.time}>{formatTime(message.timestamp)}</span>
                            </div>
                        </div>
                    </div>
                );
            }


        } else if (message.from_Usergroup === "bot") {
            let prevSenderIdRealtime;
            let isConsecutive = false
            // console.log("Index hello", index)

            if (index !== 0) {
                prevSenderIdRealtime = messages[index - 1];

                if (prevSenderIdRealtime === undefined || prevSenderIdRealtime === null) {
                    isConsecutive = false;
                } else {
                    isConsecutive = prevSenderIdRealtime.from_Usergroup === message.from_Usergroup
                }
            }

            messageElement = (
                <div className={styles['owner-top']}>
                    <div className={styles.owner}>
                        {!isConsecutive && (
                            <span>
                                {'Bot'}
                            </span>
                        )}
                        <div className={styles.agent}>
                            <p>{ greetingMessage? greetingMessage :  message.content}</p>
                            <span className={styles.time}>{formatTime(message.timestamp)}</span>
                        </div>
                    </div>
                </div>
            );

        } else if (message.from_Usergroup === "action") {

            messageElement = (
                <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '15px', display: "flex", justifyContent: "center", flexDirection: "column" }}>
                    <span style={{ alignSelf: "center", fontSize: "11px", color: "grey", marginBottom: "-4px" }}>{formatTime(message.timestamp)}</span>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{
                            margin: '10px 0px',
                            color: 'rgb(88 88 88)',
                            fontSize: '13px',
                            opacity: 0.9,
                            padding: '5px 10px',
                            width: 'fit-content',
                            fontWeight: 400,
                            
                            borderRadius: '14px',

                            background: "rgb(226 226 226)"

                        }}>

                            <span>
                                {message.action_status === "chat-started" ? <IoChatboxOutline /> : message.action_status === "visitor-left" ? <CiLogout /> : <CiLock />}
                            </span>
                            <span style={{ marginLeft: "4px" }}>
                                {message.action_status === "chat-started" ? (
                                    <span>
                                        Live Chat Started by{" "}
                                        <span style={{ color: "#4db8ff", fontWeight: 400 }}>
                                            {message.from_Username}
                                        </span>
                                    </span>
                                ) : (
                                    message.action_status
                                )}
                            </span>

                        </div>
                    </div>
                </div>
            );



        }

        return (
            <React.Fragment key={index}>
                {(CurrentdateStatus !== dateHeader) && (
                    <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '30px' }}>
                        <span style={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: '10px 0',
                            color: 'rgb(47 47 47)',
                            fontSize: '12px',
                            opacity: 0.7
                        }}>
                            <div style={{
                                flex: 1,
                                background: 'linear-gradient(to right, rgba(0, 0, 0, 0), rgb(198 198 198))',
                                height: '1px',
                                // width:'20%',
                                margin: '0 10px',
                                opacity: 0.9
                            }} />
                            {CurrentdateStatus = dateHeader}
                            <div style={{
                                flex: 1,
                                background: 'linear-gradient(to left, rgba(0, 0, 0, 0), rgb(198 198 198))',
                                height: '1px',
                                width: '20%',
                                margin: '0 10px'
                            }} />
                        </span>
                    </div>
                )}
                {messageElement}
            </React.Fragment>
        );
    });


    function getLastActionType() {
       
        for (let i = messages.length - 1; i >= 0; i--) {
          if (messages[i].from_Usergroup === "action") {
            return (
                <div className={styles['ended-chat-inside-text']}>
                  <BiLockOpenAlt className={styles.lock} />
                  {messages[i].action_status}
                </div>
              );
          }
        }
      }


     
    return (
        <div style={{ width: "100%" }}>
            {pdfPreview && (
                            <div className={styles['pdf-overlay']}>
                                <div className={styles['pdf-container']}>
                                    <PDFViewer pdfUrl={pdfPreview} />
                                </div>
                                <span className={styles.icon_container}>
                                    <IoMdClose onClick={() => setPdfPreview(null)} color='white' style={{ width: "25px", height: "25px" }} />
                                </span>
                            </div>
                        )}
                        
            <div className={(SelectedPreview || selectedPdfPreview) ? styles["messages-output-with-img-selected"] : styles["messages-output"]} style={{ position: 'relative', display: 'flex', flexDirection: 'column', backgroundColor: '#f8f8f9' }}>

                {/* Header */}
                <Header page={props.selectedUser.page} selectedUser={props.selectedUser} setChatState={setChatState} chatState={chatState} username={username} currentAuthUsername={currentAuthUsername} selectedUserSessionId={selectedUserSessionId} />
                {/* header end */}

                {(!isLoading && messages.length < 1) && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '55vh', marginTop: "25px" }}>
                        <span>No Conversation Yet</span>
                    </div>
                )}

                {/* Messages */}
                <div style={{ overflowY: "scroll", marginBottom: "20px" }}>
                    {isLoading ? (
                        // Render a loader while messages are loading
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '55vh', marginTop: "25px" }}>

                            <div className={styles.loader}></div>
                        </div>
                    ) : (
                        <div
                            ref={messageContainerRef}
                            style={{
                                // flexGrow: 1,
                                // marginBottom: '20px',
                                // marginTop: '30px',
                            }}
                        >
                            {showMessages}
                            <div ref={messageEndRef}></div>
                        </div>
                    )}

                </div>
                {/* Typing Indicator */}
                {(isTyping && !messageSend && props.selectedUser.page === "ActiveChat" && fromUser === selectedUser.sessionID) && (
                    <div style={{ gap:"6px",maxWidth: '95%', width: "fit-content", overflowWrap: "Anywhere", padding: '2px', borderRadius: '5px', fontStyle: 'italic', marginTop: 'auto', marginBottom: '6px', marginLeft: '6px', display: "flex" }}>
                        <div className={styles.ticontainer}>
                            <div className={styles.tiblock}>
                                <div className={styles.tidot}></div>
                                <div className={styles.tidot}></div>
                                <div className={styles.tidot}></div>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center",gap:"3px" }}>

                            <span style={{ opacity: '0.9', fontWeight: '600', fontSize: '12px', color: 'rgb(8, 44, 103)' }}>User is typing: </span>
                            <span style={{ color: 'rgb(8, 44, 103)', fontSize: '12px', opacity: '0.9', fontWeight: '400' }}>{UserTypingContent}</span>
                            <span className={styles["typing-message-content"]}></span>
                        </div>
                    </div>
                )}
            </div>
            {(Isconversation === true && props.selectedUser.page === "history") && (
                <div className={styles['ended-chat']} >
                   
                    {getLastActionType()}
                </div>
            )}

            {username ? (
                props.selectedUser.page === "ActiveChat" && (
                    (chatState && chatState.isChatEnded && !isLoading) ? (
                        // <div className={styles.container}>
                        //     <div className={styles.chatCompletedBadge}>
                        //     CHAT COMPLETED
                        //     </div>
                        //     <div>
                        //         Ended by imraan asif when the visitor was online
                        //     </div>

                        // </div>

                        <div className={styles['container']} >
                            <div className={styles.chatCompletedBadge}>
                                CHAT COMPLETED
                            </div>
                            <div className={styles.lock}>
                                <BiLockOpenAlt /> This Chat has been Closed
                            </div>
                        </div>
                    ) : (
                        (chatState && !chatState.isChatEnded && !isLoading) && (
                            <div className={styles["input-message"]}>
                                <form onSubmit={(e) => onMessage(e, messageContent)}>
                                    <div className={styles.form}>
                                        <div className={styles['in-chat']}>
                                            <input placeholder="Send Message" value={messageContent} onChange={(e) => getContent(e)} />
                                            <div className={styles["in-icon"]}>
                                                <Tooltip title="Attachment">
                                                    <label htmlFor='fileInput'>
                                                        <GrAttachment style={{ width: "20px", height: "20px" }} className={styles.icon} />
                                                    </label>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                            ref={ref}
                                            accept="image/*,.pdf"
                                        />
                                        <button type="submit" disabled={!selectedUserId} className={styles.send}>
                                            <BsFillSendFill />
                                        </button>
                                    </div>
                                </form>
                                {SelectedPreview && (
                                    <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#e8e8e8', padding: '0.4rem', margin: "0.5rem", width: "fit-content", borderRadius: "10px", marginTop: "25px" }}>
                                        <img src={SelectedPreview} alt="Selected" style={{ width: '45px', height: '45px', marginRight: '10px', objectFit: "contain" }} />
                                        <p style={{ marginRight: '10px', fontSize: "12px", margin: "0" }}>{selectedFileName}</p>
                                        <button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => handleIconClick()}>
                                            <IoCloseOutline style={{ width: "20px", height: "20px", color: "black" }} className={styles.icon} />
                                        </button>
                                    </div>
                                )}

                                {selectedPdfPreview && (
                                    <div onClick={() => setPdfPreview(selectedPdfUrl)} style={{ display: 'flex', alignItems: 'center', backgroundColor: '#e8e8e8', padding: '0.4rem', margin: "0.5rem", width: "fit-content", borderRadius: "10px", marginTop: "25px", cursor: "pointer" }}>
                                        <img src={pdfImage} alt="Selected" style={{ width: '45px', height: '45px', marginRight: '10px', objectFit: "contain" }} />
                                        <p style={{ marginRight: '10px', fontSize: "12px", margin: "0" }}>{selectedFileName.length <= 8 ? selectedFileName : selectedFileName.substring(0, 4) + "....pdf"}</p>
                                        <button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={(e) => handleIconClick(e)}>
                                            <IoCloseOutline style={{ width: "20px", height: "20px", color: "black" }} className={styles.icon} />
                                        </button>
                                    </div>
                                )}



                                {/* {selectedPdfPreview && (
                                    <div className={styles.pdfPreview_container} onClick={() => setPdfPreview(selectedFile)}>
                                        <span className={styles.pdfPreview_iconContainer}>
                                            <img src={pdfImage} alt="Selected" style={{ width: '45px', height: '45px', marginRight: '10px', objectFit: "contain" }} />


                                        </span>
                                        <p style={{ marginRight: '10px', fontSize: "12px", margin: "0" }}>{selectedFileName.length <= 8 ? selectedFileName : selectedFileName.substring(0, 4) + "....pdf"}</p>
                                        <button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => handleIconClick()}>
                                            <IoCloseOutline style={{ width: "20px", height: "20px", color: "black" }} className={styles.icon} />
                                        </button>
                                    </div>
                                )} */}
                            </div>
                        )
                    )
                )
            ) : (
                <span>Please select a user to start a conversation</span>
            )}

        </div>
    )
}