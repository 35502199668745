// PersonalInfoStep.jsx
import React, { useState, useEffect } from "react";
import { TextField, LinearProgress } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import AccountCircle from '@mui/icons-material/AccountCircle';
import InputAdornment from '@mui/material/InputAdornment';
import { BiHide, BiShow } from 'react-icons/bi';
import styles from '../forceReset.module.css'; // Import your CSS module

const CssTextField = styled(TextField)({
    // '& label.Mui-focused': {
    //   color: '#f37440',
    // },x
    // '& .MuiInput-underline:after': {
    //   borderBottomColor: '#f37440',
    // },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#DBDBDB',
        },

        "& input::placeholder": {
            fontSize: "13px"
        },
        width: '370px',
        borderRadius: "11px"

        // '&:hover fieldset': {
        //   borderColor: 'yellow',
        // },
        // '&.Mui-focused fieldset': {
        //   borderColor: '#f37440',
        // },
    },
});

const FormComponent = ({ formFields, handleFieldChange, passwordStrength, setPasswordStrength }) => {
    const [showConfirmPass, setShowConfirmPass] = useState(false);
    const [showNewPass, setShowNewPass] = useState(false);
    const [passwordContainsNumber, setPasswordContainsNumber] = useState(false);
    const [passwordContainsUpperCase, setPasswordContainsUpperCase] = useState(false);
    const [passwordContainsSpecialChar, setPasswordContainsSpecialChar] = useState(false);
    const [lengthCondition, setLengthCondition] = useState(false)

    const handleShowConfirmPass = () => {
        setShowConfirmPass(!showConfirmPass);
    };

    const handleShowNewPass = () => {
        setShowNewPass(!showNewPass);
    };

    useEffect(() => {

        if (formFields.newPassword.length >= 6) {
            setLengthCondition(true)
        } else {
            setLengthCondition(false)
        }

        const containsNumber = /\d/.test(formFields.newPassword);
        setPasswordContainsNumber(containsNumber);

        const containsUpperCase = /[A-Z]/.test(formFields.newPassword);
        setPasswordContainsUpperCase(containsUpperCase);


        const containsSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(formFields.newPassword);
        setPasswordContainsSpecialChar(containsSpecialChar);
        const strength = (containsNumber + containsUpperCase + containsSpecialChar + lengthCondition) / 4;
        setPasswordStrength(strength);
    }, [formFields.newPassword]);
    return (
        <>

            <CssTextField
                type={showNewPass ? 'text' : 'password'}
                // placeholder='Enter password'
                name='newPassword'
                placeholder="New Password"
                value={formFields.password}
                onChange={(e) => handleFieldChange(e.target.name, e.target.value)}
                required
                fullWidth
                autoComplete='new-password'
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M15.1721 6.52854V3.66854C15.1726 2.92138 14.869 2.20618 14.3313 1.68746C13.7935 1.16873 13.0679 0.891143 12.3212 0.918538H9.31456C8.5679 0.891143 7.84224 1.16873 7.3045 1.68746C6.76675 2.20618 6.46322 2.92138 6.46372 3.66854V6.52854C4.56731 7.20009 3.41384 9.12254 3.71372 11.1119L4.58456 16.401C5.00316 18.5274 6.89125 20.0441 9.05789 19.9944H12.6146C14.7812 20.0441 16.6693 18.5274 17.0879 16.401L17.9587 11.1119C18.2541 9.1125 17.0831 7.18641 15.1721 6.52854ZM11.7346 13.6877L11.9271 14.256C12.0115 14.5162 11.9674 14.8009 11.8082 15.0233C11.6491 15.2458 11.3938 15.3794 11.1204 15.3835H10.4421C10.1686 15.3794 9.91334 15.2458 9.75421 15.0233C9.59508 14.8009 9.55099 14.5162 9.63539 14.256L9.82789 13.6877C9.93168 13.3666 9.85069 13.0145 9.61706 12.771C9.09762 12.1539 9.14999 11.2387 9.73643 10.6849C10.3229 10.131 11.2396 10.131 11.826 10.6849C12.4125 11.2387 12.4648 12.1539 11.9454 12.771C11.7118 13.0145 11.6308 13.3666 11.7346 13.6877ZM7.83872 6.21687H8.16872V6.22604H13.7971V3.66854C13.7624 2.8872 13.1027 2.28083 12.3212 2.31187H9.31456C8.53307 2.28083 7.87343 2.8872 7.83872 3.66854V6.21687Z" fill="#083A5E" />
                            </svg>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <div className={styles['iconDivLock']} >
                                {showNewPass ? (
                                    <BiHide onClick={handleShowNewPass} className={styles['eye-icon']} />
                                ) : (
                                    <BiShow onClick={handleShowNewPass} className={styles['eye-icon']} />
                                )}
                            </div>
                        </InputAdornment>
                    ),
                }}
            />

            <CssTextField
                type={showConfirmPass ? 'text' : 'password'}
                // placeholder='Enter password'
                name='confirmPassword'
                placeholder="Confirm Password"
                value={formFields.password}
                onChange={(e) => handleFieldChange(e.target.name, e.target.value)}
                required
                fullWidth
                autoComplete='new-password'
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M15.1721 6.52854V3.66854C15.1726 2.92138 14.869 2.20618 14.3313 1.68746C13.7935 1.16873 13.0679 0.891143 12.3212 0.918538H9.31456C8.5679 0.891143 7.84224 1.16873 7.3045 1.68746C6.76675 2.20618 6.46322 2.92138 6.46372 3.66854V6.52854C4.56731 7.20009 3.41384 9.12254 3.71372 11.1119L4.58456 16.401C5.00316 18.5274 6.89125 20.0441 9.05789 19.9944H12.6146C14.7812 20.0441 16.6693 18.5274 17.0879 16.401L17.9587 11.1119C18.2541 9.1125 17.0831 7.18641 15.1721 6.52854ZM11.7346 13.6877L11.9271 14.256C12.0115 14.5162 11.9674 14.8009 11.8082 15.0233C11.6491 15.2458 11.3938 15.3794 11.1204 15.3835H10.4421C10.1686 15.3794 9.91334 15.2458 9.75421 15.0233C9.59508 14.8009 9.55099 14.5162 9.63539 14.256L9.82789 13.6877C9.93168 13.3666 9.85069 13.0145 9.61706 12.771C9.09762 12.1539 9.14999 11.2387 9.73643 10.6849C10.3229 10.131 11.2396 10.131 11.826 10.6849C12.4125 11.2387 12.4648 12.1539 11.9454 12.771C11.7118 13.0145 11.6308 13.3666 11.7346 13.6877ZM7.83872 6.21687H8.16872V6.22604H13.7971V3.66854C13.7624 2.8872 13.1027 2.28083 12.3212 2.31187H9.31456C8.53307 2.28083 7.87343 2.8872 7.83872 3.66854V6.21687Z" fill="#083A5E" />
                            </svg>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <div className={styles['iconDivLock']} >
                                {showConfirmPass ? (
                                    <BiHide onClick={handleShowConfirmPass} className={styles['eye-icon']} />
                                ) : (
                                    <BiShow onClick={handleShowConfirmPass} className={styles['eye-icon']} />
                                )}
                            </div>
                        </InputAdornment>
                    ),
                }}
            />

            <div className={styles['password-requirements']}>
                {formFields.newPassword.length > 0 && (
                    <div style={{ marginBottom: "25px" }}>
                        <LinearProgress
                            variant="determinate"
                            value={passwordStrength * 100}
                            color={passwordStrength >= 0.7 ? 'success' : 'inherit'}
                        />
                    </div>
                )}

                <div style={{ marginTop: "-10px" }}>
                    <ul>
                        <li style={{ color: lengthCondition ? '#b8b8b8' : '#000' }}>Be at least 6 characters long</li>
                        <li style={{ color: passwordContainsNumber ? '#b8b8b8' : '#000' }}>Contain at least one number</li>
                        <li style={{ color: passwordContainsUpperCase ? '#b8b8b8' : '#000' }}>Include both uppercase and lowercase characters</li>
                        <li style={{ color: passwordContainsSpecialChar ? '#b8b8b8' : '#000' }}>Include Special Character</li>
                    </ul>
                </div>
            </div>


        </>
    )


};

export default FormComponent;
