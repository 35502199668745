import styles from "../forgotPassword.module.css"
import { MdFingerprint } from "react-icons/md";
import Button from '@mui/material/Button';
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { Backdrop } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { TextField, LinearProgress } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import AccountCircle from '@mui/icons-material/AccountCircle';
import InputAdornment from '@mui/material/InputAdornment';

const CssTextField = styled(TextField)({
    // '& label.Mui-focused': {
    //   color: '#f37440',
    // },x
    // '& .MuiInput-underline:after': {
    //   borderBottomColor: '#f37440',
    // },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#DBDBDB',
        },

        "& input::placeholder": {
            fontSize: "13px"
        },
        width: '370px',
        borderRadius: "11px"

        // '&:hover fieldset': {
        //   borderColor: 'yellow',
        // },
        // '&.Mui-focused fieldset': {
        //   borderColor: '#f37440',
        // },
    },
});

export default function EmailComponent({ email, setEmail, handleEmailSend, emailError, loading, handleEmailChange }) {
    const navigate = useNavigate()
    console.log("email component...")


    return (
        <div className={styles.email_container}>
            <div className={styles.top_section}>
                <div className={styles.icon_container}>
                    <span className={styles.icon}>
                        <MdFingerprint style={{ width: "20px", height: "20px" }} />
                    </span>
                </div>
                <div className={styles.top_section_text}>
                    <p className={styles.forgot_password_p}>Forgot Password?</p>
                    <p style={{ color: "#8E8EA0", fontSize: "17px", textAlign: "center" }}>No worries, we'll send you reset instructions.</p>
                </div>
            </div>
            <div className={styles.bottom_section}>
                <div className={styles.email_field_container}>
                    {/* <label htmlFor="" style={{ fontSize: "15px" }}>Email</label> */}
                    {/* <input className={styles.input_field} placeholder="Enter your email" type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} /> */}
                    {/* {emailError && <span style={{ color: "red", fontSize: "12px" }}>{emailError}</span>} */}
                    <CssTextField

                        variant="outlined"
                        fullWidth
                        FormHelperTextProps={{ style: { color: '#d32f2f', width: '370px', marginLeft: '0px', } }}
                        placeholder="Email"
                        value={email}
                        onChange={(e) => handleEmailChange(e.target.value)}
                        required
                        helperText={emailError}
                        error={!!emailError}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M4.99984 3.33331H14.9998C16.8408 3.33331 18.3332 4.8257 18.3332 6.66665V14.1666C18.3332 16.0076 16.8408 17.5 14.9998 17.5H4.99984C3.15889 17.5 1.6665 16.0076 1.6665 14.1666V6.66665C1.6665 4.8257 3.15889 3.33331 4.99984 3.33331ZM11.1665 11.2916L16.4498 7.37498C16.6856 7.20164 16.7377 6.87067 16.5665 6.63331C16.4849 6.51999 16.3609 6.44452 16.2227 6.42411C16.0846 6.40371 15.9441 6.44009 15.8332 6.52498L10.4915 10.4166C10.3578 10.5567 10.1726 10.636 9.979 10.636C9.78537 10.636 9.60017 10.5567 9.4665 10.4166L4.1665 6.52498C4.05615 6.44276 3.91756 6.40797 3.78146 6.4283C3.64536 6.44864 3.523 6.52243 3.4415 6.63331C3.26852 6.86851 3.31681 7.19908 3.54984 7.37498L8.79984 11.25C9.12652 11.5599 9.55792 11.7354 10.0082 11.7416C10.4369 11.7416 10.8501 11.581 11.1665 11.2916Z" fill="#083A5E" />
                                    </svg>
                                </InputAdornment>
                            ),
                        }}
                    />
                    
                </div>
                <div style={{width:"370px"}}>
                    <Button sx={{ backgroundColor: '#F27340' }} onClick={handleEmailSend} variant="contained" fullWidth>Send</Button>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div className={styles.login_btn_container} onClick={() => navigate("/login")}>
                        <IoIosArrowRoundBack style={{ width: "25px", height: "25px" }} />
                        <span style={{ fontSize: "15px", fontWeight: "500" }}>Back to log in</span>
                    </div>
                </div>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}

            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}