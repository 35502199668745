import { BsThreeDotsVertical } from "react-icons/bs";
import CustomSelect from "../../../../../Components/CustomSelect/CustomSelect";
import { FaChevronDown, FaRegSmile } from "react-icons/fa";
import { GiBottomRight3DArrow } from "react-icons/gi";
import { TbWorld } from "react-icons/tb";
import { RiSendPlane2Fill } from "react-icons/ri";
import { MdChatBubble } from "react-icons/md";
import styles from "./botComponent.module.css"
import comcatOrange from "../../../../../Img/comcatLogoOrange.png"
import comcatText from "../../../../../Img/comcatText.png"
import { useEffect, useState } from "react";

export default function BotComponent({ color, appearanceData, buttonLabelChecked, botAppearanceData, status, setStatus, profilePicture, botChatLabel, setBotChatLabel, setButtonLabelChecked, handleStatusChange }) {
    console.log("Status of Bot: ", status.value)
    const [openBot, setOpenBot] = useState(false)
    const options = [
        { label: "Online", value: "online" },
        { label: "Offline", value: "offline" }
    ]

    return (
        <div style={{ width: "27%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
            <div className={styles.bot_top} style={{ justifyContent: "space-between" }}>
                <div><span>Preview</span></div>
                <div><CustomSelect options={options} onChange={handleStatusChange} selectedValueProp={status} /></div>
            </div>
            <div className={styles.bot_container}>
                {/* <div className={styles.bot_top} style={{ justifyContent: "space-between" }}>
                    <div><span>Preview</span></div>
                    <div><CustomSelect options={options} onChange={setStatus} selectedValueProp={status} /></div>
                </div> */}

                {/* bot content */}
                <div>

               
                {
                    openBot &&
                    <div className={`${styles.bot_chat_section} ${openBot && styles.bot_appear}`}>
                        <div className={styles.bot_chat_section_top} style={{ backgroundColor: color }}>
                            <div className={styles.bot_top}>
                                <img src={
                                    status.value === "offline" ?
                                        botAppearanceData.botAvatar || "https://picsum.photos/seed/picsum/200/300" :
                                        profilePicture

                                } alt="" className={styles.user_img} />
                                <div className={styles.name_container}>
                                    <span style={{ fontSize: "12px", fontWeight: "500" }}>
                                        {status.value === "offline" ?
                                            botAppearanceData?.botName : appearanceData?.userName
                                        }
                                    </span>
                                    <span style={{ fontSize: "10px", color: "gray" }}>{status.value === "offline" ? botAppearanceData?.botOnlineText :  appearanceData?.onlineText}</span>
                                </div>
                            </div>
                            <div className={styles.bot_top}>
                                <BsThreeDotsVertical style={{ width: "20px", height: "20px" }} />
                                <FaChevronDown style={{ width: "20px", height: "20px" }} />
                            </div>
                        </div>
                        <div className={styles.chat_container} >
                            <div className={styles.main_chat_section}>
                                <div className={styles.message_container}><p>Hi! I'm Mr. Comcat Nice to Meet You!</p></div>
                                <div className={styles.message_container}><p>What brought you here today?</p></div>
                            </div>
                            {
                                status.value === "offline" &&
                                <div className={styles.main_chat_section} style={{ alignItems: "center", gap: "5px", marginTop: "-31px" }}>
                                    <div className={styles.chat_options}>
                                        <GiBottomRight3DArrow />
                                        <span>Using ChatBot</span>
                                    </div>
                                    <div className={styles.chat_options}>
                                        <FaRegSmile />
                                        <span>I have Questions</span>
                                    </div>
                                    <div className={styles.chat_options}>
                                        <TbWorld />
                                        <span>Just Browsing</span>
                                    </div>
                                </div>
                            }


                            {/* <div className={styles.main_chat_section} style={{ gap: "2px", marginTop: "auto" }}>
                        <div className={styles.chat_input_container} style={{ position: "relative", width: "100%", gap: "10px" }} >
                            <FaRegSmile style={{ width: "18px", height: "18px" }} />
                            <input type="text" className={styles.chat_input} placeholder="Write your message" disabled />
                            <RiSendPlane2Fill style={{ width: "18px", height: "18px", color: color }} />
                        </div>
                        <div className={styles.chat_input_bottom}>
                            <span style={{ fontSize: "10px", color: "gray", marginTop: "4px" }}>Powered by</span>
                            <div>
                                <img src={comcatOrange} alt="comcat.png" style={{ width: "18px", height: "18px" }} />
                                <img src={comcatText} alt="" style={{ width: "35px" }} />
                            </div>
                        </div>
                    </div> */}
                        </div>
                        <div className={styles.main_chat_section} style={{ gap: "2px", marginTop: "auto" }}>
                            <div className={styles.chat_input_container} style={{ position: "relative", width: "100%", gap: "10px" }} >
                                <FaRegSmile style={{ width: "18px", height: "18px" }} />
                                <input type="text" className={styles.chat_input} placeholder="Write your message" disabled />
                                <RiSendPlane2Fill style={{ width: "18px", height: "18px", color: color }} />
                            </div>
                            <div className={styles.chat_input_bottom}>
                                <span style={{ fontSize: "10px", color: "gray", marginTop: "4px" }}>Powered by</span>
                                <div>
                                    <img src={comcatOrange} alt="comcat.png" style={{ width: "18px", height: "18px" }} />
                                    <img src={comcatText} alt="" style={{ width: "35px" }} />
                                </div>
                            </div>
                        </div>
                    </div>}


                {/* chat button */}
                <div style={{ display: "flex", justifyContent: "end", alignItems: "center", padding: "0" }}>
                    <div className={styles.bot_top} style={{ padding: "0" }}>
                        {/* for online */}
                        {(buttonLabelChecked || botChatLabel) &&
                            <div className={styles.offline_text_container}>
                                <span className={styles.offline_text1}>{
                                    status.value === "online" ?
                                        appearanceData?.topText : botAppearanceData?.botChatTextTop
                                }</span>
                                <span className={styles.offline_text2}>{
                                    status.value === "online" ?
                                        appearanceData?.bottomText : botAppearanceData?.botChatTextBottom
                                }</span>
                            </div>
                        }
                        <div className={styles.chat_icon} style={{ backgroundColor: color }} onClick={() => setOpenBot(!openBot)}>
                            <MdChatBubble />
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>

    )
}