// PersonalInfoStep.jsx
import React, { useState, useEffect } from "react";
import { TextField, LinearProgress } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import AccountCircle from '@mui/icons-material/AccountCircle';
import InputAdornment from '@mui/material/InputAdornment';
import { BiHide, BiShow } from 'react-icons/bi';
import styles from '../Signup.module.css'; // Import your CSS module

const CssTextField = styled(TextField)({
    // '& label.Mui-focused': {
    //   color: '#f37440',
    // },x
    // '& .MuiInput-underline:after': {
    //   borderBottomColor: '#f37440',
    // },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#DBDBDB',
        },

        "& input::placeholder": {
            fontSize: "13px"
        },
        width: '370px',
        borderRadius:"11px"

        // '&:hover fieldset': {
        //   borderColor: 'yellow',
        // },
        // '&.Mui-focused fieldset': {
        //   borderColor: '#f37440',
        // },
    },
});

const PersonalInfoStep = ({ formFields, handleFieldChange, errorEmail, error }) => {
    const [show, setShow] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [passwordContainsNumber, setPasswordContainsNumber] = useState(false);
    const [passwordContainsUpperCase, setPasswordContainsUpperCase] = useState(false);
    const [passwordContainsSpecialChar, setPasswordContainsSpecialChar] = useState(false);

    const handleShow = () => {
        setShow(!show);
    };
    useEffect(() => {

        const containsNumber = /\d/.test(formFields.password);
        setPasswordContainsNumber(containsNumber);


        const containsUpperCase = /[A-Z]/.test(formFields.password);
        setPasswordContainsUpperCase(containsUpperCase);


        const containsSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(formFields.password);
        setPasswordContainsSpecialChar(containsSpecialChar);
        const strength = (containsNumber + containsUpperCase + containsSpecialChar) / 3;
        setPasswordStrength(strength);
    }, [formFields.password]);
    return (
        <>
            <CssTextField

                variant="outlined"
                fullWidth

                placeholder="Full Name"
                value={formFields.name}
                onChange={(e) => handleFieldChange('name', e.target.value)}
                required

                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M16.6401 22H7.36009C6.34927 21.9633 5.40766 21.477 4.79244 20.6742C4.17722 19.8713 3.95266 18.8356 4.18009 17.85L4.42009 16.71C4.69613 15.1668 6.02272 14.0327 7.59009 14H16.4101C17.9775 14.0327 19.3041 15.1668 19.5801 16.71L19.8201 17.85C20.0475 18.8356 19.823 19.8713 19.2077 20.6742C18.5925 21.477 17.6509 21.9633 16.6401 22Z" fill="#083A5E" />
                                <path d="M12.5001 12H11.5001C9.29096 12 7.50009 10.2092 7.50009 8.00001V5.36001C7.49743 4.46807 7.85057 3.61189 8.48127 2.98119C9.11197 2.35049 9.96815 1.99735 10.8601 2.00001H13.1401C14.032 1.99735 14.8882 2.35049 15.5189 2.98119C16.1496 3.61189 16.5028 4.46807 16.5001 5.36001V8.00001C16.5001 9.06088 16.0787 10.0783 15.3285 10.8284C14.5784 11.5786 13.561 12 12.5001 12Z" fill="#083A5E" />
                            </svg>
                        </InputAdornment>
                    ),
                }}
            />
            <CssTextField

                variant="outlined"
                fullWidth
                FormHelperTextProps={{ style: { color: '#d32f2f', width: '370px', marginLeft: '0px', } }}
                placeholder="Email"
                value={formFields.email}
                onChange={(e) => handleFieldChange('email', e.target.value)}
                required
                helperText={errorEmail}
                error={!!errorEmail}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M4.99984 3.33331H14.9998C16.8408 3.33331 18.3332 4.8257 18.3332 6.66665V14.1666C18.3332 16.0076 16.8408 17.5 14.9998 17.5H4.99984C3.15889 17.5 1.6665 16.0076 1.6665 14.1666V6.66665C1.6665 4.8257 3.15889 3.33331 4.99984 3.33331ZM11.1665 11.2916L16.4498 7.37498C16.6856 7.20164 16.7377 6.87067 16.5665 6.63331C16.4849 6.51999 16.3609 6.44452 16.2227 6.42411C16.0846 6.40371 15.9441 6.44009 15.8332 6.52498L10.4915 10.4166C10.3578 10.5567 10.1726 10.636 9.979 10.636C9.78537 10.636 9.60017 10.5567 9.4665 10.4166L4.1665 6.52498C4.05615 6.44276 3.91756 6.40797 3.78146 6.4283C3.64536 6.44864 3.523 6.52243 3.4415 6.63331C3.26852 6.86851 3.31681 7.19908 3.54984 7.37498L8.79984 11.25C9.12652 11.5599 9.55792 11.7354 10.0082 11.7416C10.4369 11.7416 10.8501 11.581 11.1665 11.2916Z" fill="#083A5E" />
                            </svg>
                        </InputAdornment>
                    ),
                }}
            />

            <CssTextField
                type={show ? 'text' : 'password'}
                // placeholder='Enter password'
                name='password'
                placeholder="Password"
                value={formFields.password}
                onChange={(e) => handleFieldChange('password', e.target.value)}
                required
                FormHelperTextProps={{ style: { color: '#d32f2f', width: '370px', marginLeft: '0px', } }}
                helperText={error}
                error={!!error}
                fullWidth
                autoComplete='new-password'
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M15.1721 6.52854V3.66854C15.1726 2.92138 14.869 2.20618 14.3313 1.68746C13.7935 1.16873 13.0679 0.891143 12.3212 0.918538H9.31456C8.5679 0.891143 7.84224 1.16873 7.3045 1.68746C6.76675 2.20618 6.46322 2.92138 6.46372 3.66854V6.52854C4.56731 7.20009 3.41384 9.12254 3.71372 11.1119L4.58456 16.401C5.00316 18.5274 6.89125 20.0441 9.05789 19.9944H12.6146C14.7812 20.0441 16.6693 18.5274 17.0879 16.401L17.9587 11.1119C18.2541 9.1125 17.0831 7.18641 15.1721 6.52854ZM11.7346 13.6877L11.9271 14.256C12.0115 14.5162 11.9674 14.8009 11.8082 15.0233C11.6491 15.2458 11.3938 15.3794 11.1204 15.3835H10.4421C10.1686 15.3794 9.91334 15.2458 9.75421 15.0233C9.59508 14.8009 9.55099 14.5162 9.63539 14.256L9.82789 13.6877C9.93168 13.3666 9.85069 13.0145 9.61706 12.771C9.09762 12.1539 9.14999 11.2387 9.73643 10.6849C10.3229 10.131 11.2396 10.131 11.826 10.6849C12.4125 11.2387 12.4648 12.1539 11.9454 12.771C11.7118 13.0145 11.6308 13.3666 11.7346 13.6877ZM7.83872 6.21687H8.16872V6.22604H13.7971V3.66854C13.7624 2.8872 13.1027 2.28083 12.3212 2.31187H9.31456C8.53307 2.28083 7.87343 2.8872 7.83872 3.66854V6.21687Z" fill="#083A5E" />
                            </svg>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <div className={styles['iconDivLock']} >
                                {show ? (
                                    <BiHide onClick={handleShow} className={styles['eye-icon']} />
                                ) : (
                                    <BiShow onClick={handleShow} className={styles['eye-icon']} />
                                )}
                            </div>
                        </InputAdornment>
                    ),
                }}
            />

            <div className={styles['password-requirements']}>
                {formFields.password.length > 0 && (
                     <div style={{marginBottom: "25px" }}>
                    <LinearProgress
                        variant="determinate"
                        value={passwordStrength * 100}
                        color={passwordStrength >= 0.7 ? 'success' : 'inherit'}
                    />
                    </div>
                )}




                <div style={{ marginTop: "-10px" }}>
                    <ul>
                        <li style={{ color: formFields.password.length >= 6 ? '#b8b8b8' : '#000' }}>Be at least 6 characters long</li>
                        <li style={{ color: passwordContainsNumber ? '#b8b8b8' : '#000' }}>Contain at least one number</li>
                        <li style={{ color: passwordContainsUpperCase ? '#b8b8b8' : '#000' }}>Include both uppercase and lowercase characters</li>
                        <li style={{ color: passwordContainsSpecialChar ? '#b8b8b8' : '#000' }}>Include Special Character</li>
                    </ul>
                </div>
            </div>

        </>
    )


};

export default PersonalInfoStep;
