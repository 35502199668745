


import React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { IoMdLogIn } from "react-icons/io";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import styles from "./activityInfo.module.css"
import { ImLink } from "react-icons/im";
import { CiLogout } from "react-icons/ci";



const ActivityInfo = ({ userData, selecteduser,iconContainer,iconColor }) => {

  let trackActivityArray = userData[0].trackActivityArray
  let vistorUsername = userData[0].username
  console.log("ActivityInfo userData===>", trackActivityArray)
  // let trackActivityArray = [
  //   {
  //     "activityType": "Landed",
  //     "activityTime": "Thursday 15 February 2024 2:11:27 AM",
  //     "visitLink": "https://stampasolutions.support/heaters/",
  //     "_id": {
  //       "$oid": "65cd2c7fa2052938b37bfbad"
  //     }
  //   },
  //   {
  //     "activityType": "connection",
  //     "activityTime": "Thursday 15 February 2024 2:11:27 AM",
  //     "visitLink": "https://stampasolutions.support/heaters/",
  //     "_id": {
  //       "$oid": "65cd2c7fa2052938b37bfbad"
  //     }
  //   },
  //   {
  //     "activityType": "Navigated",
  //     "activityTime": "Thursday 15 February 2024 2:12:27 AM",
  //     "visitLink": "https://stampasolutions.support/central-air-conditioners/",
  //     "_id": {
  //       "$oid": "65cd2cb5a2052938b37bfbc8"
  //     }
  //   },
  //   {
  //     "activityType": "Navigated",
  //     "activityTime": "Thursday 15 February 2024 2:12:59 AM",
  //     "visitLink": "https://stampasolutions.support/faq/",
  //     "_id": {
  //       "$oid": "65cd2cdca2052938b37bfc0d"
  //     }
  //   },
  //   {
  //     "activityType": "Navigated",
  //     "activityTime": "Thursday 15 February 2024 2:14:08 AM",
  //     "visitLink": "https://stampasolutions.support/design-planning/",
  //     "_id": {
  //       "$oid": "65cd2d20a2052938b37bfd6e"
  //     }
  //   },
  //   {
  //     "activityType": "disconnect",
  //     "activityTime": "Thursday 15 February 2024 2:15:04 AM",
  //     "_id": {
  //       "$oid": "65cd2d58a2052938b37bfdc5"
  //     }
  //   },
  //   {
  //     "activityType": "Navigated",
  //     "activityTime": "Thursday 18 February 2024 2:35:05 AM",
  //     "visitLink": "https://stampasolutions.support/",
  //     "_id": {
  //       "$oid": "65cd320aa2052938b37bff9a"
  //     }
  //   },
  //   {
  //     "activityType": "Navigated",
  //     "activityTime": "Thursday 18 February 2024 2:37:22 AM",
  //     "visitLink": "https://stampasolutions.support/furnances/",
  //     "_id": {
  //       "$oid": "65cd3293a2052938b37bfff9"
  //     }
  //   },
  //   {
  //     "activityType": "disconnect",
  //     "activityTime": "Thursday 18 February 2024 2:37:51 AM",
  //     "_id": {
  //       "$oid": "65cd32afa2052938b37c0095"
  //     }
  //   },
  // ]

  const getTrackingData = (trackActivityArray) => {
    const steps = trackActivityArray.map((activity, index) => {
      let description = '';
      let timeDifference = '';

      if (activity.activityType === 'disconnect') {
        description = 'Visitor Left';
      } else {
        const isReturnVisit = index > 0 && trackActivityArray[index - 1].activityType === 'disconnect';
        const linkPath = activity.visitLink && activity.visitLink.split('/').filter(Boolean).pop(); // Check if visitLink is not undefined before splitting
        if (activity.activityType === 'Landed') {
          description = `${vistorUsername} Landed`;
        } else {
          description = isReturnVisit
            ?
            <span className={styles.landing_description}>
              Visitor Returned:
              {" "}
              <a href={activity.visitLink} target="_blank" rel="noopener noreferrer">
                {`/${linkPath}`}
              </a>
            </span>
            : (
              <span className={styles.landing_description}>
                Navigated to:
                {" "}
                <a href={activity.visitLink} target="_blank" rel="noopener noreferrer">
                  {`/${linkPath}`}
                </a>
              </span>
            );


          // Check if there is a next index and the next activity type is not 'disconnect'
          let landed_time = ""
          if (index < trackActivityArray.length - 1) {
            const nextActivityTime = new Date(trackActivityArray[index + 1].activityTime);
            const currentActivityTime = new Date(activity.activityTime);
            const landed_minutes = currentActivityTime.getHours();
            const landed_seconds = currentActivityTime.getMinutes().toString().padStart(2, '0'); // Ensure two digits for seconds
            landed_time = `${landed_minutes}:${landed_seconds}`
            console.log("Landed Time : ", landed_time)
            const timeDiff = nextActivityTime - currentActivityTime;

            const minutes = Math.floor(timeDiff / (1000 * 60));
            const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

            timeDifference = `${minutes} minutes ${seconds} seconds`;
          }
        }
      }

      return {

        landed_time: `${new Date(activity.activityTime).getHours().toString().padStart(2, '0')}:${new Date(activity.activityTime).getMinutes().toString().padStart(2, '0')}`,
        description,
        timeDifference,
      };
    });
    return steps.slice().reverse();
  }



  const test = {}

  const getDate = (dateString) => {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = dateObj.toLocaleString('default', { month: 'long' });
    const day = dateObj.getDate();
    return `${month} ${day}, ${year}`;
  }

  trackActivityArray.forEach(activity => {
    const date = getDate(activity.activityTime)
    if (test[date]) {
      test[date].push(activity)
    } else {
      test[date] = [activity]
    }
  })

  const tracking_data = Object.keys(test).map(key => {
    return {
      date: key,
      data: getTrackingData(test[key])
    }
  })

  const reversedTracking = tracking_data.slice().reverse()

  console.log("Tracking Data : ", reversedTracking);


  const getRow = (description, landed_time, time_difference = "") => {
    // console.log("Description : ", description, "Landed Time : ", landed_time, "Time Difference : ", time_difference)
    if (!time_difference) {
      return (<div className={styles.row}>
        <span className={styles.landing_time}>{landed_time}</span>
        <span style={{ backgroundColor : iconContainer? iconContainer: "#0d6efd" }} className={styles.icon_container}>
          <IoMdLogIn style={{ color: iconColor? iconColor : "rgb(255 255 255)", width: "20px", height: "20px" }} />
        </span>
        <span className={styles.landing_description}>{description}</span>
      </div>)
    }
    else {
      return (
        <div className={styles.row}>
          <span className={styles.landing_time}>{landed_time}</span>
          <div style={{ backgroundColor : iconContainer? iconContainer: "#0d6efd" }}  className={styles.icon_container}>
            <ImLink style={{ color: iconColor? iconColor : "rgb(255 255 255)", width: "16px", height: "16px" }} />
          </div>
          <div>
            {/* <span className={styles.landing_time}>Navigated to <a href='#'>/furnance</a></span> */}
            {description}
            <p className={styles.time_spent_container}>Average time spent:  <span className={styles.time_spent}> {time_difference}</span></p>
          </div>
        </div>
      )
    }
  }


  return (

    <div className={styles.activityListDiv}>
      {reversedTracking.map(tracking => {
        const { date, data } = tracking
        return (
          <>
            <div className={styles.chat_date}>
              <span>{date}</span>
              <div className={styles.horizontal_line}></div>
            </div>
            <div style={{ padding: "15px 25px", overflowX: "hidden" }}>

              {data.map((step, index) => {
                return (
                  <>
                    {step.timeDifference !== "" ? getRow(step.description, step.landed_time, step.timeDifference) : getRow(step.description, step.landed_time)}
                    {index !== data.length - 1 && <div className={styles.line}>
                    </div>}

                  </>
                )
              })}
            </div>
          </>

        )
      })}

      {/* <div className={styles.chat_date}>
        <span>15 Feb, 2024</span>
        <div className={styles.horizontal_line}></div>
      </div>
      <div style={{ padding: "15px 35px", overflowX: "hidden" }}>

        {reversedSteps.map((step, index) => {
          return (
            <>
              {step.timeDifference !== "" ? getRow(step.description, step.landed_time, step.timeDifference) : getRow(step.description, step.landed_time)}
              {index !== reversedSteps.length - 1 && <div className={styles.line}>
              </div>}

            </>
          )
        })}
      </div> */}
    </div>


  );
};

export default ActivityInfo;
