// CompanyInfoStep.jsx
import React from 'react';
import { TextField } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';


const CssTextField = styled(TextField)({
  // '& label.Mui-focused': {
  //   color: '#f37440',
  // },x
  // '& .MuiInput-underline:after': {
  //   borderBottomColor: '#f37440',
  // },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#DBDBDB',
    },

    "& input::placeholder": {
      fontSize: "13px"
    },
    width: '370px', // Set the width to 370px
    // '&:hover fieldset': {
    //   borderColor: 'yellow',
    // },
    // '&.Mui-focused fieldset': {
    //   borderColor: '#f37440',
    // },
  },
});

const CompanyInfoStep = ({ formFields, handleFieldChange, error }) => (
  <>
    <CssTextField
      placeholder="Company Name"
      variant="outlined"
      fullWidth
      value={formFields.companyName}
      onChange={(e) => handleFieldChange('companyName', e.target.value)}
      required
    />
    <CssTextField
      FormHelperTextProps={{ style: { color: '#d32f2f', width: '370px', marginLeft: '0px', } }}
      placeholder="Company URL"
      variant="outlined"
      fullWidth
      value={formFields.companyURL}
      onChange={(e) => handleFieldChange('companyURL', e.target.value)}
      required
      error={error}
      helperText={error}
    />
  </>
);

export default CompanyInfoStep;
