
import axios from 'axios';

const API_BASE_URL = 'https://prod.api.comcat.ai';
const authToken = "ComcatChatbotApi123$"

const sendRequest = async (endpoint, method, data, params = {}) => {
    try {
        const config = {
            method: method,
            url: `${API_BASE_URL}/${endpoint}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: authToken,
            },
            data: data,
            params: params,
        };

        const response = await axios(config);
        return response.data;
    } catch (error) {
        throw new Error(`Error: ${error.message}`);
    }
};

export const createUser = async (userData) => {
    return await sendRequest('api/createUser', 'POST', userData);
};

export const addUser = async (userData) => {
    return await sendRequest('api/addUser', 'POST', userData);
};

export const deleteUser = async (userData) => {

    return await sendRequest('api/deleteUser', 'DELETE', userData, null);
};

export const fetchDisconnectedUsers = async (widgetID) => {
    return await sendRequest('users/disconnectedUsers', 'GET', null, { widgetID });
};

export const createSession = async (username, attributes) => {
    return await sendRequest('session', 'POST', { username, ...attributes });
};

export const fetchBotMessages = async (widgetID) => {
    return await sendRequest('api/dataarray', 'GET', null, { widgetID });

};

export const fetchIsActiveChat = async (admin, sessionID) => {
    return await sendRequest('changeIsActiveChat', 'GET', null, { admin, sessionID });
};

export const updateAdminSchema = async () => {
    return await sendRequest('updateAdmin', 'POST', null);
};

export const fetchOrganizationData = async (widgetID) => {
    return await sendRequest('api/getOrganizationData', 'GET', null, { widgetID });
};

export const fetchAdminList = async (widgetID) => {
    return await sendRequest('getAdminsList', 'GET', null, { widgetID });
};

export const listAudioFiles = async () => {
    return await sendRequest('listAudioFiles', 'GET', null);
};

export const fetchSounds = async (username) => {
    console.log("username for fetchSound", username)
    return await sendRequest('getNotificationSounds', 'GET', null, { username });
};

export const updateNotificationSounds = async (username, sounds) => {
    const data = { sounds };
    return await sendRequest('UpdateNotificationSounds', 'POST', data, { username });
};

export const getOnlineStatus = async (username) => {
    return await sendRequest('getAdminOnlineStatus', 'GET', null, { username });
};


export const checkUserAndSendEmail = async (username, verificationCode) => {
    return await sendRequest('checkUserAndSendEmail', 'GET', null, { username, verificationCode });
};

export const resendVerificationCode = async (username, verificationCode) => {
    return await sendRequest('resendVerificationCode', 'GET', null, { username,verificationCode });
};



export const updateWidgetAppearance = async (widgetID, appearanceData) => {
    console.log("widgetID===>",widgetID)
    const data = { appearanceData };
    return await sendRequest('updateWidgetAppearance', 'POST', data, { widgetID });
};
