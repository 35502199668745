import styles from "./appearance.module.css"


import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../../../Service/AuthContext";
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useGlobalVariablesContext } from "../../../../Service/GlobalVariables";
import { TextField } from '@mui/material';
import ErrorSnackbar from "../../../../Components/ErrorSnackbar/ErrorSnackbar";
import SuccessSnackbar from "../../../../Components/SuccessSnackbar/SuccessSnackbar";
import Avatar from '@mui/material/Avatar';
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { MdBrush, MdDeleteOutline } from "react-icons/md";
import { BsThreeDots } from "react-icons/bs";
import { FaCheck, FaUser } from "react-icons/fa";
import Switch from '@mui/material/Switch';
import { BsBookHalf } from "react-icons/bs";
import { FaEye } from "react-icons/fa";
import HomeIcon from "../../../../Components/MenuSidebar/Icons/HomeIcon";
import ColorPicker from "./appearance_components/ColorPicker/ColorPicker";
import { HiUpload } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";

import avatar1 from "./avatars/avatar1.png"
import avatar2 from "./avatars/avatar2.png"
import avatar3 from "./avatars/avatar3.png"
import avatar4 from "./avatars/avatar4.png"
import avatar5 from "./avatars/avatar5.png"
import avatar6 from "./avatars/avatar6.png"
import avatar7 from "./avatars/avatar7.png"
import avatar8 from "./avatars/avatar8.png"
import { grey } from "@mui/material/colors";
import * as api from '../../../../Service/API'; // Import your API functions

const avatars = [avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7, avatar8]

const IOSSwitch = styled(({ label, ...props }) => (
    <div style={{ display: "flex", flex: 2 }}>
        {/* <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: "60px" }}>
            <span style={{ fontSize: '14px' }}>{label === 'on' ? 'Available' : 'Busy'}</span>
        </div> */}

        <div>
            <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
        </div>

    </div>
))(({ theme, label }) => ({
    width: 38,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : 'orange',
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
        ...(label === 'off' && {
            '& + .MuiSwitch-track': {
                backgroundColor: 'orange',
            },
        }),
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 16,
        height: 16,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));


const CssTextField = styled(TextField)({
    // '& label.Mui-focused': {
    //   color: '#f37440',
    // },x
    // '& .MuiInput-underline:after': {
    //   borderBottomColor: '#f37440',
    // },


    // "& fieldset": { border: 'none' },

    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#edededde',
            width: "none"
        },

        "& input::placeholder": {
            fontSize: "13px"
        },
        width: '370px',
        borderRadius: "12px"

        // '&:hover fieldset': {
        //   borderColor: 'yellow',
        // },
        // '&.Mui-focused fieldset': {
        //   borderColor: '#f37440',
        // },
    },
    "& .MuiInputBase-root.Mui-disabled": {
        backgroundColor: "#3e638c0d",
        '& fieldset': {
            border: 'none !important',
        },
    },



});

export default function Appearance({ botColor, setColor, appearanceData, handleInputChange, buttonLabelChecked, setButtonLabelChecked, botAppearanceData, handleBotInputChange, handleBotAvatarChange, status, botChatLabel, setBotChatLabel, handleBotChatLabel, handleUserChatLabel }) {


    const styleConfirmModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        height: 200,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [isModalOpen, setModalOpen] = useState(false);
    const colorPickerBtnRef = useRef();

    const [soundWidgetChecked, setSoundWidgetChecked] = useState(false);
    const { widgetID, userAttributes, setUserAttributes } = useAuth();

    const [openConfirm, setOpenConfirm] = useState(false);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState({ isOpen: false, message: "" });
    const [isErrorSnackbarOpen, setIsErrorSnackbarOpen] = useState({ isOpen: false, message: "" });
    const [changesInProgress, setChangesInProgress] = useState(false);

    const [showPicker, setShowPicker] = useState(false)
    const [showWidgetAppearance, setShowWidgetAppearance] = useState(false)
    const [showBotAppearance, setShowBotAppearance] = useState(false)
    const [previewURL, setPreviewURL] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const modalRef = useRef();

    const onClose = () => {
        setModalOpen(!isModalOpen)
    }

    const handleModalClose = () => {
        setOpenConfirm(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsSnackbarOpen({ isOpen: false, message: "" });
    };

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsErrorSnackbarOpen({ isOpen: false, message: "" });
    };

    const handleErrorSnackbar = (isOpen, message) => {
        setIsErrorSnackbarOpen({ isOpen, message })
    }

    const handleSnackbarOpen = (isOpen, message) => {
        setIsSnackbarOpen({ isOpen, message })
    }

    const closePicker = (e) => {
        setShowPicker(!showPicker)
    }

    const saveBotAppearanceData = () => {
        console.log("Bot Data : ", botAppearanceData)
    }


    const saveApperanceChanges = async () => {
        console.log("Data:", appearanceData);
        setOpenConfirm(false)
        setChangesInProgress(true);
        try {
            const response = await api.updateWidgetAppearance(widgetID, appearanceData);
            console.log('Data updated successfully:', response);
            setChangesInProgress(false);
            setIsSnackbarOpen({ isOpen: true, message: "Changes have been saved" });
        } catch (error) {
            console.error('Error updating data:', error);
            setChangesInProgress(false);
            setIsErrorSnackbarOpen({ isOpen: true, message: "Failed to save changes. Please try again." });
        }
    }




    const saveAppearanceData = () => {
        appearanceData.color = botColor
        console.log("Data : ", appearanceData)
        setOpenConfirm(true)
    }

    const handleImageUploadModal = () => {
        if (status.value === "online") {
            return
        }
        setModalOpen(!isModalOpen)
    }

    const handleIconContainerClick = () => {
        // Trigger file input click
        const fileInput = document.getElementById('fileInput');
        fileInput.click();
    };

    const handleRemoveImage = () => {
        console.log("Remove File")
        setPreviewURL(null)
        setSelectedFile(null)
        // setChangesMade(!changesMade)
    }
    const handleFileInputChange = (e) => {
        if (e.target.files.length !== 0) {
            const file = e.target.files[0];
            const reader = new FileReader();

            reader.onload = (event) => {
                const image = new Image();
                image.src = event.target.result;

                image.onload = () => {
                    const canvas = document.createElement('canvas');
                    const MAX_WIDTH = 800; // Set your desired maximum width
                    const MAX_HEIGHT = 600; // Set your desired maximum height
                    let width = image.width;
                    let height = image.height;

                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(image, 0, 0, width, height);

                    canvas.toBlob((blob) => {
                        const compressedFile = new File([blob], file.name, {
                            type: file.type,
                            lastModified: Date.now(),
                        });

                        setSelectedFile(compressedFile);
                    }, file.type, 0.7);

                    setPreviewURL(URL.createObjectURL(file));
                    // handleBotAvatarChange(previewURL)
                    URL.revokeObjectURL(URL.createObjectURL(file));

                    // Clear the file input value to allow re-selecting the same file
                    e.target.value = null;
                };
            };

            reader.readAsDataURL(file);
        }
        // setChangesMade(true);
    };

    const handleImageUpload = () => {
        console.log("preview url : ", previewURL)
        console.log("Selected File : ", selectedFile)
        handleBotAvatarChange(previewURL)
        setModalOpen(false)

    }

    const handleModalCloseBtnClick = () => {
        handleCancelClick()
        setModalOpen(!isModalOpen)
    }

    const handleCancelClick = () => {
        setSelectedFile(null)
        // setChangesMade(false)
        setPreviewURL("")
    };

    const operator_modal_form = () => {

        return (
            <div ref={modalRef} style={{ display: isModalOpen && status?.value === "offline" ? "block" : "none", textAlign: 'center' }} className={styles.modalStyles}>
                <div className={styles.modal_top}>

                    <div className={styles.close_btn_container} onClick={handleModalCloseBtnClick}>
                        <IoClose style={{ width: "20px", height: "20px", cursor: "pointer" }} />
                    </div>
                    <div style={{ marginLeft: "130px" }}>
                        <h1 style={{ textAlign: 'left', fontSize: '22px', color: "gray", }}>Profile Picture</h1>
                    </div>
                    <div onClick={handleImageUpload} className={styles.action_btn} style={{ color: "gainsboro", height: "max-content", backgroundColor: "gray", padding: "10px 25px" }}>
                        <p style={{ color: "gainsboro", fontSize: "12px", margin: "0", fontWeight: "500" }}>SAVE CHANGES</p>
                    </div>
                </div>

                <div className={styles.modal_content_container} style={{ position: "relative" }}>
                    {/* <div className={styles.action_btns_container}>
                        <div onClick={handleIconContainerClick} className={styles.action_btn}>
                            <span className={styles.icon_container}>
                                <HiUpload style={{ width: "30px", height: '30px' }} />
                            </span>
                            <p style={{ fontSize: "20px", margin: "0" }}>Upload picture</p>
                        </div>
                        <div className={styles.remove_image_btn_container} onClick={handleRemoveImage}>
                            <span className={styles.icon_container}>
                                <MdDeleteOutline style={{ width: "30px", height: '30px' }} />
                            </span>
                            <p style={{ fontSize: "20px", margin: "0" }}>Remove picture</p>
                        </div>
                    </div> */}
                    <div className={`${styles.upload_icon_container_modal} ${styles.upload_icon_container_modal_new}`} style={{ padding: previewURL ? "0px" : '10px', borderRadius: !previewURL ? "100px" : "", border: !previewURL ? "1px solid #DBDBDB" : "", position: "absolute", width: "150px", height: "150px" }} onClick={handleIconContainerClick}>
                        {previewURL ? (
                            <img src={previewURL} alt="Preview" className={styles.upload_image_modal} />
                        ) : (
                            <FaUserCircle className={styles.FaUser_image_modal} />
                        )}
                    </div>
                    <input
                        type="file"
                        id="fileInput"
                        style={{ display: 'none' }}
                        onChange={handleFileInputChange}
                        accept="image/jpeg, image/jpg, image/png"
                    />


                </div>
                <div className={styles.modal_bottom_section} style={{ display: "flex", flexDirection: "column", paddingTop: "48px", gap: "40px" }}>
                    <div><p style={{ margin: "0", color: "gray" }}>Choose your avatar</p></div>
                    <div style={{ display: "flex", gap: "10px", justifyContent: "space-around" }}>
                        {avatars.map(avatar => {
                            return (
                                <img src={avatar} style={{ width: "75px", height: "75px", cursor: "pointer" }} alt="" onClick={() => setPreviewURL(avatar)} />
                            )
                        })}
                    </div>
                    <div style={{ display: "flex", gap: "15px", justifyContent: "center", alignItems: "center" }}>
                        <span className={styles.horizontal_line} ></span>
                        <span style={{ color: "#182e42", fontWeight: "bold" }}>OR</span>
                        <span className={styles.horizontal_line} ></span>
                    </div>
                    <div style={{ display: "flex", gap: "15px", justifyContent: "center", alignItems: "center" }}>
                        <div>
                            <p style={{ margin: "0", color: "gray" }}>Select profile picture from your device</p>
                        </div>
                        <div onClick={handleIconContainerClick} className={styles.action_btn}>
                            {/* <span className={styles.icon_container}> */}
                            <HiUpload style={{ width: "18px", height: '18px' }} />
                            {/* </span> */}
                            <p style={{ fontSize: "12px", margin: "0" }}>UPLOAD PHOTO</p>
                        </div>
                        <div className={styles.action_btn} onClick={handleRemoveImage} style={{ backgroundColor: "transparent", color: "black", border: "1px solid gray" }}>
                            {/* <span className={styles.icon_container}> */}
                            <MdDeleteOutline style={{ width: "18px", height: '18px' }} />
                            {/* </span> */}
                            <p style={{ fontSize: "12px", margin: "0" }}>REMOVE PHOTO</p>
                        </div>
                    </div>
                    {/* <div className={styles.close_save_btns_container}>
                        <button onClick={handleModalCloseBtnClick} className={styles.close_btn}>Close</button>
                        <button className={styles.save_btn} onClick={handleImageUpload}>Save</button>
                    </div> */}
                </div>


            </div>
        )
    }

    return (
        <div className={styles.installation_container}>
            <div className={styles.widget_alert}>
                <div className={styles.personal_details_container}>
                    <MdBrush className={styles.account_icon} style={{ color: "gray" }} />
                    <p style={{ fontSize: "12px", margin: 0 }}>Your chat is not installed. <a href="" style={{ color: "black" }}>Install chat widget</a></p>
                </div>
            </div>



            {/* widget appearance */}
            <div className={styles.top_bar} onClick={() => setShowWidgetAppearance(!showWidgetAppearance)} style={{ cursor: "pointer" }}>
                <div className={styles.personal_details_container}>
                    <MdBrush className={styles.account_icon} style={{ color: "gray" }} />
                    <p className={styles.p_text}>Widget appearance</p>
                </div>
                <div className={styles.button_container}>
                    {showWidgetAppearance ? <IoIosArrowUp className={styles.account_icon} /> : <IoIosArrowDown className={styles.account_icon} />}
                </div>

            </div>


            {/* widget appearance content */}
            <div className={`${styles.installation_guide_container} ${showWidgetAppearance && styles.show_appearance}`}>
                {/* right side */}
                <div className={styles.right_side}>
                    <div className={styles.top_section}>

                        <div className={styles.input_container} style={{ position: "relative" }}>
                            <div className={styles.label_container}>
                                <label htmlFor="">Background color</label>
                            </div>
                            <div className={styles.colors_container}>
                                <div className={`${styles.edit_color_circle} ${styles.pick_color_circle}`} style={{ backgroundColor: "#223952", cursor: "pointer" }} onClick={() => setColor("#223952")}>
                                    {botColor === "#223952" && <FaCheck color="white" />}
                                </div>
                                <div className={`${styles.edit_color_circle} ${styles.pick_color_circle}`} style={{ backgroundColor: "green", cursor: "pointer" }} onClick={() => setColor("green")}>
                                    {botColor === "green" && <FaCheck color="white" />}
                                </div>
                                <div className={`${styles.edit_color_circle} ${styles.pick_color_circle}`} style={{ backgroundColor: "purple", cursor: "pointer" }} onClick={() => setColor("purple")}>
                                    {botColor === "purple" && <FaCheck color="white" />}
                                </div>
                                <div className={`${styles.edit_color_circle} ${styles.pick_color_circle}`} style={{ backgroundColor: "#3e7ec3", cursor: "pointer" }} onClick={() => setColor("#3e7ec3")}>
                                    {botColor === "#3e7ec3" && <FaCheck color="white" />}
                                </div>
                                <div className={`${styles.edit_color_circle} ${styles.pick_color_circle}`} style={{ backgroundColor: "black", cursor: "pointer" }} onClick={() => setColor("black")}>
                                    {botColor === "black" && <FaCheck color="white" />}
                                </div>
                                <div ref={colorPickerBtnRef} className={`${styles.edit_color_circle} ${styles.pick_color_circle}`} style={{ border: "1px solid gray", cursor: "pointer" }} onClick={closePicker} >
                                    <BsThreeDots />
                                </div>
                            </div>
                            {showPicker && <ColorPicker setColor={setColor} color={botColor} setShowPicker={setShowPicker} colorPickerBtnRef={colorPickerBtnRef} />}

                        </div>

                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div className={styles.input_container}>
                                <div className={styles.label_container}>
                                    <label htmlFor="">Online status</label>
                                </div>
                                <div className={styles.custom_input_container}>
                                    <input maxLength={15} disabled={status.value === "online" ? false : true} placeholder="We reply immediately" className={styles.custom_input} type="text" name="onlineText" value={appearanceData.onlineText} id="" onChange={handleInputChange} />
                                </div>
                            </div>
                            <span className={styles.chat_message_container}>Show this message in chat widget when you'r 'online' in your comcat panel</span>
                        </div>

                        {/* <div style={{ display: "flex", flexDirection: "column" }}>
                            <div className={styles.input_container}>
                                <div className={styles.label_container}>
                                    <label htmlFor="">Label text</label>
                                </div>
                                <div className={styles.custom_input_container}>
                                    <input disabled={status.value === "offline" ? true : false} placeholder="Chat with us" className={styles.custom_input} type="text" name="labelText" id="" onChange={handleInputChange} value={appearanceData.labelText} />
                                </div>
                            </div>
                            <span className={styles.chat_message_container}>Include a short message next to the closed chat icon</span>
                        </div> */}

                        <div className={styles.widgetButtonLabel}>
                            <p style={{margin:"0"}}>Widget Button</p>
                        </div>

                        <div className={styles.input_container}>
                            <div className={styles.label_container}>
                                <label htmlFor="">Button label</label>
                            </div>
                            {/* <label htmlFor="">Show this message in chat widget when you'r 'online' in your comcat panel</label> */}
                            <IOSSwitch
                                disabled={status.value === "offline" ? true : false}
                                checked={buttonLabelChecked}
                                // onChange={() => setButtonLabelChecked(!buttonLabelChecked)}
                                onChange={handleUserChatLabel}
                                // label={checked ? 'on' : 'off'}
                                sx={{ m: 1 }}
                            />
                        </div>

                        {buttonLabelChecked && status.value !== "offline" &&
                            <>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div className={styles.input_container}>
                                        <div className={styles.label_container}>
                                            <label htmlFor="">Offline status</label>
                                        </div>
                                        <div className={styles.custom_input_container}>
                                            <input disabled={status.value === "offline" ? true : false} maxLength={20} placeholder="We reply immediately" className={styles.custom_input} type="text" name="topText" id="" onChange={handleInputChange} value={appearanceData.topText} />
                                        </div>
                                    </div>
                                    <span className={styles.chat_message_container}>Show this text beside chat icon on top when you'r 'Offline'.</span>
                                </div>

                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div className={styles.input_container}>
                                        <div className={styles.label_container}>
                                            <label htmlFor="">Offline status</label>
                                        </div>
                                        <div className={styles.custom_input_container}>
                                            <input disabled={status.value === "offline" ? true : false} maxLength={20} placeholder="We reply immediately" className={styles.custom_input} type="text" name="bottomText" id="" onChange={handleInputChange} value={appearanceData.bottomText} />
                                        </div>
                                    </div>
                                    <span className={styles.chat_message_container}>Show this text beside chat icon on bottom when you'r 'Offline'.</span>
                                </div>
                            </>
                        }

                        {/* <div className={styles.input_container}>
                            <div className={styles.label_container}>
                                <label htmlFor="">Enable widget sounds</label>
                            </div>
                            <IOSSwitch
                                checked={soundWidgetChecked}
                                onChange={() => setSoundWidgetChecked(!soundWidgetChecked)}
                                // label={checked ? 'on' : 'off'}
                                sx={{ m: 1 }}
                            />
                        </div> */}

                        <div style={{ display: "flex", alignItems: "center", gap: "10px", color: "blue" }}>
                            <BsBookHalf />
                            <p style={{ margin: 0, fontSize: "12px" }}>Learn how to customize your widget</p>
                        </div>

                    </div>


                    <div style={{ marginBottom: "30px", marginTop: "30px" }}>
                        <button disabled={status.value === "offline" ? true : false} className={styles.saveButton} onClick={saveAppearanceData} style={{ opacity: status.value === "offline" ? "0.5" : "" }} >Save</button>
                    </div>

                </div>
            </div>

            {/* Bot appearance */}
            <div className={styles.top_bar} onClick={() => setShowBotAppearance(!showBotAppearance)} style={{ cursor: "pointer" }}>
                <div className={styles.personal_details_container}>
                    <MdBrush className={styles.account_icon} style={{ color: "gray" }} />
                    <p className={styles.p_text}>Bot appearance</p>
                </div>
                <div className={styles.button_container}>
                    {showBotAppearance ? <IoIosArrowUp className={styles.account_icon} /> : <IoIosArrowDown className={styles.account_icon} />}
                </div>
            </div>

            {/* Bot appearance content */}
            <div className={`${styles.installation_guide_container} ${showBotAppearance && styles.show_appearance}`}>
                {/* right side */}
                <div className={styles.right_side}>
                    <div className={styles.top_section}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div className={styles.input_container}>
                                <div className={styles.label_container}>
                                    <label htmlFor="">Bot Name</label>
                                </div>
                                <div className={styles.custom_input_container}>
                                    <input maxLength={20} disabled={status.value === "online" ? true : false} placeholder="We reply immediately" className={styles.custom_input} type="text" name="botName" value={botAppearanceData.botName} id="" onChange={handleBotInputChange} />
                                </div>
                            </div>
                            <span className={styles.chat_message_container}>Show this bot name on top of bot widget.</span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div className={styles.input_container}>
                                <div className={styles.label_container}>
                                    <label htmlFor="">Online status</label>
                                </div>
                                <div className={styles.custom_input_container}>
                                    <input maxLength={15} disabled={status.value === "online" ? true : false} placeholder="We reply immediately" className={styles.custom_input} type="text" name="botOnlineText" value={botAppearanceData.botOnlineText} id="" onChange={handleBotInputChange} />
                                </div>
                            </div>
                            <span className={styles.chat_message_container}>Show this text below bot name.</span>
                        </div>

                        {/* bot avatar */}

                        {/* <div style={{ display: "flex", flexDirection: "column" }}>
                            <div className={styles.input_container}>
                                <div className={styles.label_container}>
                                    <label htmlFor="">Bot Avatar</label>
                                </div>
                                <div className={styles.custom_input_container}>
                                    <input placeholder="Chat with us" className={styles.custom_input} type="text" name="labelText" id="" onChange={handleInputChange} value={appearanceData.labelText} />
                                </div>
                            </div>
                            <span className={styles.chat_message_container}>Show this avatar on top of bot widget.</span>
                        </div> */}

                        <div className={styles.upload_input_container}>
                            <label htmlFor="">Bot Avatar</label>
                            <div className={styles.upload_container}>
                                <div className={styles.upload_icon_container} style={{ padding: previewURL ? "0px" : '10px' }} onClick={handleImageUploadModal}>
                                    {previewURL ? (
                                        <img src={previewURL} alt="Preview" className={styles.upload_image} />
                                    ) : (
                                        <Avatar sx={{ fontSize: "1.5rem", bgcolor: status.value === "online" ? 'gray' : "#223952f2", height: '50px', width: '50px' }}>
                                            {userAttributes.name.charAt(0).toUpperCase()}
                                        </Avatar>
                                    )}
                                </div>

                                {
                                    status?.value === "offline" &&
                                    operator_modal_form()
                                }
                                <div style={{ display: isModalOpen && status?.value === "offline" ? "block" : "none" }} className={styles.overlayStyles}></div>

                                <p className={styles.upload_text}>Upload Image support JPEG, JPG, PNG, Max file size: 5MB</p>
                            </div>
                        </div>

                        <div className={styles.input_container}>
                            <div className={styles.label_container}>
                                <label htmlFor="">Button label</label>
                            </div>
                            {/* <label htmlFor="">Show this message in chat widget when you'r 'online' in your comcat panel</label> */}
                            <IOSSwitch
                                disabled={status.value === "online" ? true : false}
                                checked={botChatLabel}
                                // onChange={() => setBotChatLabel(!botChatLabel)}
                                onChange={handleBotChatLabel}
                                // label={checked ? 'on' : 'off'}
                                sx={{ m: 1 }}
                            />
                        </div>

                        {/* Bot chat icon texts */}
                        {botChatLabel && status.value !== "online" &&
                            <>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div className={styles.input_container}>
                                        <div className={styles.label_container}>
                                            <label htmlFor="">Offline status</label>
                                        </div>
                                        <div className={styles.custom_input_container}>
                                            <input disabled={status.value === "online" ? true : false} maxLength={20} placeholder="We reply immediately" className={styles.custom_input} type="text" name="botChatTextTop" id="" onChange={handleBotInputChange} value={botAppearanceData.botChatTextTop} />
                                        </div>
                                    </div>
                                    <span className={styles.chat_message_container}>Show this text beside chat icon on top when you'r 'Offline'.</span>
                                </div>

                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div className={styles.input_container}>
                                        <div className={styles.label_container}>
                                            <label htmlFor="">Offline status</label>
                                        </div>
                                        <div className={styles.custom_input_container}>
                                            <input disabled={status.value === "online" ? true : false} maxLength={20} placeholder="We reply immediately" className={styles.custom_input} type="text" name="botChatTextBottom" id="" onChange={handleBotInputChange} value={botAppearanceData.botChatTextBottom} />
                                        </div>
                                    </div>
                                    <span className={styles.chat_message_container}>Show this text beside chat icon on bottom when you'r 'Offline'.</span>
                                </div>
                            </>
                        }

                        <div style={{ display: "flex", alignItems: "center", gap: "10px", color: "blue" }}>
                            <BsBookHalf />
                            <p style={{ margin: 0, fontSize: "12px" }}>Learn how to customize your widget</p>
                        </div>

                    </div>
                    <div style={{ marginBottom: "30px", marginTop: "30px" }}>
                        <button disabled={status.value === "online" ? true : false} style={{ opacity: status.value === "online" ? "0.5" : "" }} className={styles.saveButton} onClick={saveBotAppearanceData}>Save</button>
                    </div>
                </div>
            </div>


            {/* bottom widgets */}
            {/* <div className={styles.bottom_widgets}>
                <div className={styles.top_bar}>
                    <div className={styles.personal_details_container}>
                        <FaEye className={styles.account_icon} style={{ color: "gray" }} />
                        <p className={styles.p_text}>Widget visibility</p>
                    </div>
                    <div className={styles.button_container}>
                        <IoIosArrowDown className={styles.account_icon} />
                    </div>

                </div>

                <div className={styles.top_bar}>
                    <div className={styles.personal_details_container}>
                        <HomeIcon className={styles.account_icon} fill="gray" />
                        <p className={styles.p_text}>Get started</p>
                    </div>
                    <div className={styles.button_container}>
                        <IoIosArrowDown className={styles.account_icon} />
                    </div>

                </div>

                <div>
                    <button className={styles.saveButton} onClick={saveAppearanceData}>Save</button>
                </div>
            </div> */}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openConfirm}
                onClose={handleModalClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openConfirm}>
                    <Box sx={styleConfirmModal}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            Alert
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            Are you sure you want to save these changes?
                        </Typography>

                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                            <Button onClick={saveApperanceChanges} variant="contained" color="primary">
                                Save
                            </Button>
                            <Button onClick={handleModalClose} variant="outlined" color="secondary" style={{ marginLeft: '1rem' }}>
                                Exit
                            </Button>
                        </div>
                    </Box>
                </Fade>
            </Modal>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={changesInProgress}
            >
                <CircularProgress color="inherit" />
            </Backdrop>


            <SuccessSnackbar setIsSnackbarOpen={setIsSnackbarOpen} isSnackbarOpen={isSnackbarOpen} handleSnackbarClose={handleSnackbarClose} />

            <ErrorSnackbar setIsErrorSnackbarOpen={setIsErrorSnackbarOpen} isErrorSnackbarOpen={isErrorSnackbarOpen} handleErrorSnackbarClose={handleErrorSnackbarClose} />

        </div>
    )
}