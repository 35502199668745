import { HiCode, HiOutlineMailOpen } from "react-icons/hi";
import Button from '@mui/material/Button';
import { IoIosArrowRoundBack } from "react-icons/io";
import styles from "../Signup.module.css"
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Backdrop } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';


export default function VerifyCodeComponent({ handleResendCode, email, handleCodeChange, handleCodeSubmit, code, inputRefs, loading, handleKeyPress, resendDisabled, timer }) {


    return (
        <div className={styles.email_container} style={{ gap: "10px" }}>
            <div className={styles.top_section}>
                {/* <div className={styles.icon_container}>
                    <span className={styles.icon}>
                        <HiOutlineMailOpen style={{ width: "20px", height: "20px" }} />
                    </span>
                </div> */}
                <div style={{ textAlign: "center" }}>
                    {/* <p className={styles.forgot_password_p}>Password reset</p> */}
                    <p style={{ color: "#8E8EA0", fontSize: "15px", textAlign: "center", margin: 0 }}>We've sent a code to <span style={{ color: "black", fontWeight: "bold" }}>{email}</span>.</p>
                    <p style={{ fontSize: "13px", color: "#8E8EA0", }}>Kindly check spam as well.</p>
                </div>
            </div>
            <div className={styles.bottom_section}>
                <div className={styles.email_field_container} style={{ flexDirection: "row", gap: "4px" }}>
                    {
                        Object.keys(code).map((fieldName, index) => (
                            <input
                                style={{ flex: "1", width: "30px", textAlign: "center", height: "50px", fontSize: "30px", backgroundColor: code[fieldName] ? "#dedede" : "" }}
                                className={styles.input_field}
                                key={fieldName}
                                name={fieldName}
                                type="text"
                                maxLength={1}
                                value={code[fieldName]}
                                onChange={(e) => handleCodeChange(e.target.name, e.target.value)}
                                onKeyUp={(e) => handleKeyPress(e.target.name, e)}
                                ref={(ref) => (inputRefs.current[index + 1] = ref)}
                            />
                        ))
                    }

                </div>

                <div style={{ textAlign: "center", color: "#8E8EA0",fontSize:"13px" }}>
                    {timer === 0 ? "Didn't receive the email?" : `Request again after ${timer} seconds`}
                    
                    <button style={{opacity:resendDisabled === true ? "0.5" : ""}} className={styles.resend_btn} disabled={resendDisabled} onClick={(e)=>handleResendCode(e)}>
                        Click resend
                    </button>

                </div>
                {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div className={styles.login_btn_container} onClick={() => navigate("/login")}>
                        <IoIosArrowRoundBack style={{ width: "25px", height: "25px" }} />
                        <span style={{ fontSize: "15px", fontWeight: "500" }}>Back to log in</span>
                    </div>
                </div> */}
            </div>
            {/* <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                
            >
                <CircularProgress color="inherit" />
            </Backdrop> */}
        </div>
    )
}