import { useState, useEffect } from "react"
import BasicTable from "./BasicTable/BasicTable"
import styles from "./live.module.css"
import MenuSideBar from "../../Components/MenuSidebar/MenuSideBar"
import Navbar from "../../Components/Navbar/Navbar"
import { useUser } from "../../Service/SelectedUserContext"
import { useConnectedUsersContext } from "../../Service/ConnectedUsersContext"
import CustomSelect from "../../Components/CustomSelect/CustomSelect"

export const Live = () => {
    const { setSelectedUser } = useUser();
    const { connectedUsers } = useConnectedUsersContext();

    const [selectFilter, setSelectFilter] = useState("")
    
    console.log("Testing")

    const options = [
        { value: 'All', label: 'All' },
        { value: 'Assigned', label: 'Assigned' },
        { value: 'Unassigned', label: 'Unassigned' },
        { value: 'Returning', label: 'Returning' },
    ];


    const handleClick = async () => {
        console.log("Testing")
    };


    const handleSelectChange = (selectedOption) => {
        // Assuming the selectedOption has a "value" property
        const selectedRole = selectedOption ? selectedOption.value : "";
        setSelectFilter(selectedRole)
    };




    useEffect(() => {
        setSelectedUser(null)
    }, []);


    return (
        <>
            <MenuSideBar
                page={"Dashboard"}
                handleClick={handleClick}
                ShowIndicatior={true}
            />
            <div className='main-mychat'>
                <Navbar />
                <div style={{ display: 'flex' }}>
                    <div className={styles.live_container}>
                        <div className={styles.live_container_content}>
                            <h1 className={styles.title}>Live Now</h1>



                            <div className={styles.dropdown_container}>
                                <div className={styles.leftContainer}>
                                    <CustomSelect
                                        width="302px"
                                        options={options}
                                        onChange={handleSelectChange}
                                        placeholder={"Filter Visitors"}
                                        borderColor={"#7e7e7e"}
                                    />
                                </div>
                                <div className={styles.rightContainer}>
                                    <p className={styles.p_text}>
                                        Visitors on your site at the moment
                                        <span className={styles.connected_users_lenght}>
                                            ({connectedUsers && connectedUsers.length ? connectedUsers.length : 0})
                                        </span>
                                    </p>
                                </div>
                            </div>

                            {/* table */}
                            <div style={{ marginTop: "13px" }}>
                                <BasicTable selectedFilter={selectFilter} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}