// Signup.jsx
import React, { useState, useEffect, useRef } from 'react';
import styles from './Signup.module.css'; // Import your CSS module
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import StepperComponent from './Signup_components/StepperComponent.js';
import comcatTextlogo from "../../Img/comcatTextlogo.png"
import { Auth } from 'aws-amplify';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import FormComponent from './Signup_components/FormComponent.js';
import { createUser } from '../../Service/API.js';
import SignupTopNav from '../../Components/Signup_topNav/SignupTopNav.js';
import ErrorSnackbar from '../../Components/ErrorSnackbar/ErrorSnackbar.js';
import SuccessSnackbar from '../../Components/SuccessSnackbar/SuccessSnackbar.js';
import * as api from '../../Service/API.js';

function Signup(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [formFields, setFormFields] = useState({
    name: '',
    email: '',
    password: '',
    companyName: '',
    companyURL: '',
    scriptText: '',
  });
  const [code, setCode] = useState({
    digit1: '',
    digit2: '',
    digit3: '',
    digit4: '',
    digit5: '',
    digit6: '',
  })

  const [resetCode, setResetCode] = useState("")
  const [error, setError] = useState('');
  const inputRefs = useRef([]);
  const [errorEmail, setErrorEmail] = useState('');
  const [widgetId, setWidgetId] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [openCreateAccount, setOpenCreateAccount] = useState(false);
  const [accountCreated, setAccountCreated] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState({ isOpen: false, message: "" })
  const [successSnackbar, setSuccessSnackbar] = useState({ isOpen: false, message: "" })
  const steps = ['Personal Info', "Verify", 'Company Info', 'Script Tag'];

  const [verificationCode, setVerificationCode] = useState(null);
  const [expirationTime, setExpirationTime] = useState(null);

  const [resendDisabled, setResendDisabled] = useState(false);
  const [timer, setTimer] = useState(0);

// use effect to handle timer for seconds
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      // Enable the button when the timer reaches 0
      setResendDisabled(false);
    }

    return () => clearInterval(interval);
  }, [timer]);


  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (unsavedChanges) {
        const message = 'You have unsaved changes. Are you sure you want to leave?';
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unsavedChanges]);

  const generateRandomStringWithTimestamp = (length) => {
    const characters = 'ABCDEFGHIJ1234KLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const timestamp = new Date().getTime().toString();
    let result = '';

    // Generate random characters
    for (let i = 0; i < length - timestamp.length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result + timestamp;
  };


  const handleCodeChange = (name, value) => {
    setCode((prevState) => ({
      ...prevState,
      [name]: value
    }))

    // Move focus to the next input field
    const fieldIndex = parseInt(name.replace('digit', ''), 10);
    if (value && fieldIndex < Object.keys(code).length) {
      inputRefs.current[fieldIndex + 1].focus();
    }
  }

  // Function to handle key press (to handle backspace)
  const handleKeyPress = (fieldName, event) => {
    const fieldIndex = parseInt(fieldName.replace('digit', ''), 10);
    console.log("Field Index in key press: ", fieldIndex)
    if (event.key === 'Backspace' && fieldIndex > 1 && !code[fieldName]) {
      // Move focus to the previous input field if backspace is pressed on an empty input
      inputRefs.current[fieldIndex - 1].focus();
    }
  };

  const handleCodeSubmit = () => {
    console.log("Code Obj: ", code);
    const resetCode = Object.values(code).join("").toString();

    if (!resetCode) {
      setErrorSnackbar({ isOpen: true, message: "Kindly enter the code then submit." });
      return null; // Return null if code is not entered
    }

    if (String(resetCode).length !== 6) {
      setErrorSnackbar({ isOpen: true, message: "Kindly enter the complete code then submit." });
      return null; // Return null if code length is not 6
    }

    return resetCode; // Return the reset code if valid
  };


  const handleAccountCreation = () => {
    setAccountCreated(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAccountCreated(false);
    setUnsavedChanges(false);
  };

  const handleFieldChange = (field, value) => {
    setUnsavedChanges(true);
    setFormFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      const newActiveStep = prevActiveStep - 1;
      if (newActiveStep === 0) {
        setCode({
          digit1: '',
          digit2: '',
          digit3: '',
          digit4: '',
          digit5: '',
          digit6: '',
        });
      }
      return newActiveStep;
    });
  };


  const generateSixDigitCode = () => {
    return Math.floor(100000 + Math.random() * 900000);
  };



  const handleResendCode = async (e) => {
    e.stopPropagation()
    try {
      const newVerificationCode = generateSixDigitCode();
      const newExpirationTime = new Date().getTime() + 60000; // 1 minute = 60,000 milliseconds

      const getResponse = await api.resendVerificationCode(formFields.email, newVerificationCode);

      if (getResponse.message === "Verification code resent successfully") {
        setVerificationCode(newVerificationCode);
        setExpirationTime(newExpirationTime);
        // Disable the button
        setResendDisabled(true);

        // Start the timer
        setTimer(90);
        console.log("Verification code resent successfully");
        setSuccessSnackbar({isOpen:true, message:"Verification code resent successfully."})
      } else {
        console.error("Failed to resend verification code");
        setErrorSnackbar({isOpen:true, message:"Failed to resend verification code."})
      }
    } catch (error) {
      console.error("Error resending verification code:", error);
      setErrorSnackbar({isOpen:true, message:"Failed to resend verification code."})
    }
  };




  const checkVerificationCode = (enteredCode) => {
    const currentTime = new Date().getTime();
    const stringEnteredCode = enteredCode.toString(); // Convert enteredCode to a string
    const stringVerificationCode = verificationCode.toString(); // Convert verificationCode to a string

    if (currentTime >= expirationTime) {
      return "expired";
    } else if (stringEnteredCode !== stringVerificationCode) {
      return "notMatched";
    } else {
      return "verified";
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (activeStep === 0 && !formFields.email.match(emailRegex)) {
      setErrorEmail('Please enter a valid email address.');
      return;
    }

    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{6,}$/;
    if (activeStep === 0 && !formFields.password.match(passwordRegex)) {
      setError(
        'Password requirements not satisfied.'
      );
      return;
    }

    var urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    if (activeStep === 2 && !formFields.companyURL.match(urlPattern)) {
      console.log("companu url error...")
      setError("Please provide valid Company Url.")
      return;
    }

    setError('');

    let updatedScript = `<script type="text/javascript" id="yourChatbot">var $yourChatbot = $yourChatbot || {};$yourChatbot.settings = {widgetid: "${widgetId}",};var d = document;var s = d.createElement("script");s.type = "text/javascript";s.id = "yourChatbotScript";s.defer = true;s.src = "https://fascinating-phoenix-0ad203.netlify.app/chatbot-bundle.js";var t = d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s, t);</script>`;

    if (activeStep === 0) {

      const VerificationCode = generateSixDigitCode();
      setVerificationCode(VerificationCode)
      setExpirationTime(new Date().getTime() + 60000)

      const getResponse = await api.checkUserAndSendEmail(formFields.email, VerificationCode);
      console.log("get api response", getResponse)

      if (getResponse.message === "User already exists") {
        console.log("User already exists")
        setErrorEmail("Email already exists.")
        return
      }


      const randomStringWithTimestamp = generateRandomStringWithTimestamp(32);
      console.log("randomStringWithTimestamp", randomStringWithTimestamp)
      setWidgetId(randomStringWithTimestamp)

    }


    if (activeStep === 1) {
      const resetCode = handleCodeSubmit();
      if (resetCode !== null) {
        const statusCode = checkVerificationCode(resetCode);
        if (statusCode === "verified") {
          console.log("Code Verified");
          handleNext()
        } else if (statusCode === "expired") {
          console.log("Code Expired");
          setErrorSnackbar({ isOpen: true, message: 'Code you entered is Expired.' })
          return
        } else if (statusCode === "notMatched") {
          console.log("The code you entered does not match. Please enter the correct code")
          setErrorSnackbar({ isOpen: true, message: 'Invalid Code! Kindly provide a valid code.' })
          return
        }
      }
    } else if (activeStep === 2) {
      setFormFields((prevFields) => ({
        ...prevFields,
        scriptText: updatedScript,
      }));
      setOpenCreateAccount(true);
      await handleCreateUser(widgetId, updatedScript);
      setTimeout(() => {
        setOpenCreateAccount(false);
        handleNext();
        handleAccountCreation();
      }, 2000);
    } else if (activeStep === 3) {
      try {
        handleOpen();
        const email = formFields.email;
        const password = formFields.password;
        const user = await Auth.signIn(email, password);

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          const newPassword = password;
          const updatedUser = await Auth.completeNewPassword(user, newPassword);
          if (updatedUser) {
            const user = await Auth.signIn(email, newPassword);
            const attributes = user.attributes;
            props.submit(email, attributes);
            handleClose();
          }
        } else {
          const attributes = user.attributes;
          props.submit(email, attributes);
          handleClose();
        }
      } catch (error) {
        console.error('Error signing in:', error);
      }
    } else {
      handleNext();
      setTimer(90)
      setResendDisabled(true)
    }
  };

  const handleCopyScriptText = () => {
    const scriptText = formFields.scriptText;
    navigator.clipboard.writeText(scriptText)
      .then(() => {
        console.log('Script text copied to clipboard');
      })
      .catch((error) => {
        console.error('Unable to copy script text to clipboard', error);
      });

    setSnackbarMessage('Text has been copied to the clipboard!');
    setIsSnackbarOpen(true);


    setTimeout(() => {
      setIsSnackbarOpen(false);
    }, 2000); // Set the duration in milliseconds
  };

  const handleCreateUser = async (widgetId, updatedScript) => {
    try {
      const userData = {
        name: formFields.name,
        email: formFields.email,
        password: formFields.password,
        companyURL: formFields.companyURL,
        companyName: formFields.companyName,
        widgetId: widgetId,
        updatedScript: updatedScript,
      };

      //API
      const response = await createUser(userData);

      console.log(response.message);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };


  return (
    <>
      <div className={styles.Signup_wrapper}>
        <SignupTopNav />

        <div className={styles.signup_form_container}>
          <div className={styles.Signup}>
            {/* <div className={styles['policy-terms-container']}>
            <p>Terms of Use</p>
            <span> | </span>
            <p>Privacy Policy</p>
          </div> */}

            {/* <div>
          <img className={styles['logo-stampa']} src={comcatTextlogo} alt="Logo" />
        </div> */}
            <div className={styles['heading-text-style']}>
              <span className={styles['Create-account']}>Create your account</span>
              {/* <p className={styles['Register-account']}>Register an account, Come and join us</p> */}
            </div>

            <div className={styles.Stepper}>
              <StepperComponent activeStep={activeStep} steps={steps} />
            </div>

            {/* { email, handleCodeChange, handleCodeSubmit, code, inputRefs, loading, handleKeyPress } */}

            <form className={styles['form-signup']} onSubmit={handleSubmit}>
              <FormComponent
                activeStep={activeStep}
                formFields={formFields}
                handleFieldChange={handleFieldChange}
                errorEmail={errorEmail}
                error={error}
                handleBack={handleBack}
                handleCopyScriptText={handleCopyScriptText}
                handleSubmit={handleSubmit}
                handleCodeChange={handleCodeChange}
                handleCodeSubmit={handleCodeSubmit}
                code={code}
                inputRefs={inputRefs}
                handleKeyPress={handleKeyPress}
                handleResendCode={handleResendCode}
                resendDisabled={resendDisabled}
                timer={timer}

              />
            </form>
            <div className={styles['policy-terms-container']}>
              <p>Terms of Use</p>
              <span> | </span>
              <p>Privacy Policy</p>
            </div>
          </div>
        </div>
      </div>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={accountCreated}
        autoHideDuration={12000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Your account has been successfully created!
        </Alert>
      </Snackbar>

      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={5000} // Adjust the duration as needed
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          variant="filled"
          severity="info"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCreateAccount}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ErrorSnackbar isErrorSnackbarOpen={errorSnackbar} setIsErrorSnackbarOpen={setErrorSnackbar} />
      <SuccessSnackbar isSnackbarOpen={successSnackbar} setIsSnackbarOpen={setSuccessSnackbar} />

    </>
  );
}

export default Signup;
