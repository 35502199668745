import React, { useEffect, useRef, useState } from 'react';
import { ChromePicker } from 'react-color';
function ColorPicker({setColor:setBotColor, color:botColor, setShowPicker, colorPickerBtnRef}) {
    const [color, setColor] = useState(botColor); // Initial color is black
    const modalRef = useRef(null);

    // Function to handle clicks outside the modal
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target) && !colorPickerBtnRef.current.contains(event.target)) {
        setShowPicker(false)
      }
    };
  
    // Add event listener when the modal is mounted
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
  
    const handleChangeComplete = (newColor) => {
      setColor(newColor.hex);
      setBotColor(newColor.hex)
      console.log("Changed Color : ", color)
    };
  
    return (
      <div style={{position:"absolute", zIndex:"999", left:"150px"}} ref={modalRef} >
        <ChromePicker color={color} onChangeComplete={handleChangeComplete} />
        {/* <div style={{ marginTop: '20px', backgroundColor: color, width: '100px', height: '50px' }}></div> */}
      </div>
    );
  }
  
  export default ColorPicker;
  