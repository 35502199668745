import React, { createContext, useContext, useEffect, useState } from 'react';

const SoundContext = createContext();

export const SoundProvider = ({ children }) => {
  const [soundData, setSoundData] = useState([]);

  let sounds = {};

  soundData.forEach(item => {
    sounds[item.type] = new Audio(item.url);
  });




  const playSound = (type) => {
    const sound = sounds[type];
   
    if (sound) {
      sound.play();
    }
  };

  
  const handleUpdateSounds = (updatedSoundData) => {
    setSoundData(updatedSoundData)
    updatedSoundData.forEach(item => {
      sounds[item.type] = new Audio(item.url);
    });
  };

  return (
    <SoundContext.Provider value={{ playSound, soundData, setSoundData,handleUpdateSounds}}>
      {children}
    </SoundContext.Provider>
  );
};

export const useSound = () => {
  return useContext(SoundContext);
};
