import { CgPassword } from "react-icons/cg";
import styles from "../forgotPassword.module.css"
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowRoundBack } from "react-icons/io";
import { useEffect, useState } from "react";
import { Backdrop } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { TextField, LinearProgress } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import { BiHide, BiShow } from 'react-icons/bi';

const CssTextField = styled(TextField)({
    // '& label.Mui-focused': {
    //   color: '#f37440',
    // },x
    // '& .MuiInput-underline:after': {
    //   borderBottomColor: '#f37440',
    // },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#DBDBDB',
        },

        "& input::placeholder": {
            fontSize: "13px"
        },
        width: '370px',
        borderRadius: "11px"

        // '&:hover fieldset': {
        //   borderColor: 'yellow',
        // },
        // '&.Mui-focused fieldset': {
        //   borderColor: '#f37440',
        // },
    },
});


export default function PasswordResetComponent({ passwordFields, handlePasswordChange, handlePasswordSubmit, passwordStrength, setPasswordStrength, loading }) {
    const navigate = useNavigate()
    const [passwordContainsNumber, setPasswordContainsNumber] = useState(false);
    const [passwordContainsUpperCase, setPasswordContainsUpperCase] = useState(false);
    const [passwordContainsSpecialChar, setPasswordContainsSpecialChar] = useState(false);
    const [lengthCondition, setLengthCondition] = useState(false)
    const [showConfirmPass, setShowConfirmPass] = useState(false);
    const [showNewPass, setShowNewPass] = useState(false);
    
    const handleShowConfirmPass = () => {
        setShowConfirmPass(!showConfirmPass);
    };

    const handleShowNewPass = () => {
        setShowNewPass(!showNewPass);
    };


    useEffect(() => {

        if (passwordFields.newPassword.length >= 6) {
            setLengthCondition(true)
        } else {
            setLengthCondition(false)
        }
        console.log("Length : ", lengthCondition, passwordFields.newPassword)

        const containsNumber = /\d/.test(passwordFields.newPassword);
        setPasswordContainsNumber(containsNumber);

        const containsUpperCase = /[A-Z]/.test(passwordFields.newPassword);
        setPasswordContainsUpperCase(containsUpperCase);


        const containsSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(passwordFields.newPassword);
        setPasswordContainsSpecialChar(containsSpecialChar);
        const strength = (containsNumber + containsUpperCase + containsSpecialChar + lengthCondition) / 4;
        setPasswordStrength(strength);
    }, [passwordFields]);

    return (
        <div className={styles.email_container}>
            <div className={styles.top_section}>
                <div className={styles.icon_container}>
                    <span className={styles.icon}>
                        <CgPassword style={{ width: "20px", height: "20px" }} />
                    </span>
                </div>
                <div className={styles.top_section_text}>
                    <p className={styles.forgot_password_p}>Set new password</p>
                    <p style={{ color: "#8E8EA0", fontSize: "17px", textAlign: "center" }}>Must be at least 6 characters long</p>
                </div>
            </div>
            <div className={styles.bottom_section}>
                <div className={styles.email_field_container}>
                    {/* <label htmlFor="" style={{ fontSize: "15px" }}>New Password</label> */}
                    {/* <input className={styles.input_field} placeholder="Enter New Password" type="text" name="newPassword" value={passwordFields.newPassword} onChange={(e) => handlePasswordChange(e.target.name,e.target.value)} />
                     */}
                    <CssTextField
                        type={showNewPass ? 'text' : 'password'}
                        name='newPassword'
                        placeholder="New Password"
                        value={passwordFields.newPassword}
                        onChange={(e) => handlePasswordChange(e.target.name,e.target.value)}
                        required
                        fullWidth
                        autoComplete='new-password'
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M15.1721 6.52854V3.66854C15.1726 2.92138 14.869 2.20618 14.3313 1.68746C13.7935 1.16873 13.0679 0.891143 12.3212 0.918538H9.31456C8.5679 0.891143 7.84224 1.16873 7.3045 1.68746C6.76675 2.20618 6.46322 2.92138 6.46372 3.66854V6.52854C4.56731 7.20009 3.41384 9.12254 3.71372 11.1119L4.58456 16.401C5.00316 18.5274 6.89125 20.0441 9.05789 19.9944H12.6146C14.7812 20.0441 16.6693 18.5274 17.0879 16.401L17.9587 11.1119C18.2541 9.1125 17.0831 7.18641 15.1721 6.52854ZM11.7346 13.6877L11.9271 14.256C12.0115 14.5162 11.9674 14.8009 11.8082 15.0233C11.6491 15.2458 11.3938 15.3794 11.1204 15.3835H10.4421C10.1686 15.3794 9.91334 15.2458 9.75421 15.0233C9.59508 14.8009 9.55099 14.5162 9.63539 14.256L9.82789 13.6877C9.93168 13.3666 9.85069 13.0145 9.61706 12.771C9.09762 12.1539 9.14999 11.2387 9.73643 10.6849C10.3229 10.131 11.2396 10.131 11.826 10.6849C12.4125 11.2387 12.4648 12.1539 11.9454 12.771C11.7118 13.0145 11.6308 13.3666 11.7346 13.6877ZM7.83872 6.21687H8.16872V6.22604H13.7971V3.66854C13.7624 2.8872 13.1027 2.28083 12.3212 2.31187H9.31456C8.53307 2.28083 7.87343 2.8872 7.83872 3.66854V6.21687Z" fill="#083A5E" />
                                    </svg>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <div className={styles['iconDivLock']} >
                                        {showNewPass ? (
                                            <BiHide onClick={handleShowNewPass} className={styles['eye-icon']} />
                                        ) : (
                                            <BiShow onClick={handleShowNewPass} className={styles['eye-icon']} />
                                        )}
                                    </div>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div className={styles.email_field_container}>
                    {/* <label htmlFor="" style={{ fontSize: "15px" }}>Confirm Password</label> */}
                    {/* <input className={styles.input_field} placeholder="Enter Confirm Password" type="text" name="confirmPassword" value={passwordFields.confirmPassword} onChange={(e) => handlePasswordChange(e.target.name, e.target.value)} /> */}
                    <CssTextField
                        type={showConfirmPass ? 'text' : 'password'}
                        // placeholder='Enter password'
                        name='confirmPassword'
                        placeholder="Confirm Password"
                        value={passwordFields.confirmPassword}
                        onChange={(e) => handlePasswordChange(e.target.name, e.target.value)}
                        required
                        fullWidth
                        autoComplete='new-password'
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M15.1721 6.52854V3.66854C15.1726 2.92138 14.869 2.20618 14.3313 1.68746C13.7935 1.16873 13.0679 0.891143 12.3212 0.918538H9.31456C8.5679 0.891143 7.84224 1.16873 7.3045 1.68746C6.76675 2.20618 6.46322 2.92138 6.46372 3.66854V6.52854C4.56731 7.20009 3.41384 9.12254 3.71372 11.1119L4.58456 16.401C5.00316 18.5274 6.89125 20.0441 9.05789 19.9944H12.6146C14.7812 20.0441 16.6693 18.5274 17.0879 16.401L17.9587 11.1119C18.2541 9.1125 17.0831 7.18641 15.1721 6.52854ZM11.7346 13.6877L11.9271 14.256C12.0115 14.5162 11.9674 14.8009 11.8082 15.0233C11.6491 15.2458 11.3938 15.3794 11.1204 15.3835H10.4421C10.1686 15.3794 9.91334 15.2458 9.75421 15.0233C9.59508 14.8009 9.55099 14.5162 9.63539 14.256L9.82789 13.6877C9.93168 13.3666 9.85069 13.0145 9.61706 12.771C9.09762 12.1539 9.14999 11.2387 9.73643 10.6849C10.3229 10.131 11.2396 10.131 11.826 10.6849C12.4125 11.2387 12.4648 12.1539 11.9454 12.771C11.7118 13.0145 11.6308 13.3666 11.7346 13.6877ZM7.83872 6.21687H8.16872V6.22604H13.7971V3.66854C13.7624 2.8872 13.1027 2.28083 12.3212 2.31187H9.31456C8.53307 2.28083 7.87343 2.8872 7.83872 3.66854V6.21687Z" fill="#083A5E" />
                                    </svg>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <div className={styles['iconDivLock']} >
                                        {showConfirmPass ? (
                                            <BiHide onClick={handleShowConfirmPass} className={styles['eye-icon']} />
                                        ) : (
                                            <BiShow onClick={handleShowConfirmPass} className={styles['eye-icon']} />
                                        )}
                                    </div>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div style={{ marginTop: "-10px" }}>
                    <ul style={{ listStyleType: "none", fontSize: "small" }}>
                        <li style={{ color: lengthCondition ? '#b8b8b8' : '#000' }}>Be at least 6 characters long</li>
                        <li style={{ color: passwordContainsNumber ? '#b8b8b8' : '#000' }}>Contain at least one number</li>
                        <li style={{ color: passwordContainsUpperCase ? '#b8b8b8' : '#000' }}>Include both uppercase and lowercase characters</li>
                        <li style={{ color: passwordContainsSpecialChar ? '#b8b8b8' : '#000' }}>Include Special Character</li>
                    </ul>
                </div>
                <div>
                    <Button sx={{ backgroundColor: '#F27340' }} onClick={handlePasswordSubmit} variant="contained" fullWidth>Submit</Button>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div className={styles.login_btn_container} onClick={() => navigate("/login")}>
                        <IoIosArrowRoundBack style={{ width: "25px", height: "25px" }} />
                        <span style={{ fontSize: "15px", fontWeight: "500" }}>Back to log in</span>
                    </div>
                </div>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}

            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}