// FormComponent.jsx
import React from 'react';
import { Button } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Link } from 'react-router-dom';
import styles from '../Signup.module.css'; 
import PersonalInfoStep from './PersonalInfoStep'; 
import CompanyInfoStep from './CompanyInfoStep'; 
import ScriptTagStep from './ScriptTagStep'; 
import VerifyCodeComponent from './VerifyCodeComponent';


const FormComponent = ({
  activeStep,
  formFields,
  handleFieldChange,
  errorEmail,
  error,
  handleBack,
  handleCopyScriptText,
  handleSubmit,
  handleCodeChange,
  handleCodeSubmit,
  code,
  handleResendCode,
  inputRefs,
  handleKeyPress,
  resendDisabled,
  timer,
}) => {
  return (
    <>
      {activeStep === 0 && (
        <PersonalInfoStep
          formFields={formFields}
          handleFieldChange={handleFieldChange}
          errorEmail={errorEmail}
          error={error}
        />
      )}
      {
        activeStep === 1 && (
          <VerifyCodeComponent 
          email={formFields.email} 
          handleCodeChange={handleCodeChange} 
          handleCodeSubmit={handleCodeSubmit}
          code={code}
          inputRefs={inputRefs}
          handleKeyPress={handleKeyPress}
          handleResendCode={handleResendCode}
          resendDisabled={resendDisabled}
          timer={timer}
          />
        )
      }
      
      {activeStep === 2 && (
        <CompanyInfoStep
          formFields={formFields}
          handleFieldChange={handleFieldChange}
          error={error}
        />
      )}
      {activeStep === 3 && <ScriptTagStep formFields={formFields} />}

      <div className={styles['button-signup-container']}>
        {activeStep === 1 && (
          <Button
            onClick={handleBack}
            variant="outlined"
            color="primary"
            size="large"
            style={{ flex: '1' }}
          >
            Back
          </Button>
        )}

        {activeStep === 3 && (
          <Button
            onClick={handleCopyScriptText}
            variant="outlined"
            color="primary"
            startIcon={<FileCopyIcon />}
            size="large"
          >
            Copy to Clipboard
          </Button>
        )}

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth={activeStep === 0 ? true : undefined}
          size="large"
          style={{ backgroundColor: 'rgb(246, 126, 78)', flex: '1' }}
        >
          {activeStep !== 3 ? 'NEXT' : 'Continue to Dashboard'}
        </Button>
      </div>
      {activeStep !== 3 && (
        <div className={styles['signin-link']}>
          Already have an account?{' '}
          <Link to="/login" style={{ color: 'rgb(246, 126, 78)', fontWeight: '500' }}>
            Login
          </Link>
        </div>
      )}
    </>
  );
};

export default FormComponent;
