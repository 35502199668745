
import styles from "./forgotPassword.module.css"

import { useRef, useState } from "react"
import EmailComponent from "./ForgotPassword_components/EmailComponent";
import ResetCodeComponent from "./ForgotPassword_components/ResetCodeComponent";
import PasswordResetComponent from "./ForgotPassword_components/PasswordResetComponent";
import ErrorSnackbar from "../../Components/ErrorSnackbar/ErrorSnackbar";
import SuccessSnackbar from "../../Components/SuccessSnackbar/SuccessSnackbar";
import SuccessComponent from "./ForgotPassword_components/SuccessComponent";
import { Auth } from 'aws-amplify';

export default function ForgotPassword() {
    const [email, setEmail] = useState("")
    const [resetCode, setResetCode] = useState("")
    const [emailError, setEmailError] = useState(false)
    const [errorSnackbar, setErrorSnackbar] = useState({ isOpen: false, message: "" })
    const [successSnackbar, setSuccessSnackbar] = useState({ isOpen: false, message: "" })
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [loading, setLoading] = useState(false)

    const [code, setCode] = useState({
        digit1: '',
        digit2: '',
        digit3: '',
        digit4: '',
        digit5: '',
        digit6: '',
    })
    const [passwordFields, setPasswordFields] = useState({ newPassword: '', confirmPassword: '' })
    const [activeStep, setActiveStep] = useState(0)
    const inputRefs = useRef([]);

    const handleEmailChange = (value) => {
        console.log("Email Change : ", value)
        setEmail(value)
    }

    const handleEmailSend = async () => {
        console.log("Email : ", email)

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (activeStep === 0 && !email.match(emailRegex)) {
            setEmailError('Please enter a valid email address.');
            return;
        }

        try {
            setLoading(true)
            await Auth.forgotPassword(email);
            setLoading(false)
            console.log('Password reset initiated. Check your email for the confirmation code.');
            setActiveStep((prevStep) => {
                return prevStep + 1;
            })
        } catch (error) {
            if (error.code === 'UserNotFoundException') {
                console.error('User not found. Please check the username and try again.');
                setLoading(false)
                setErrorSnackbar({isOpen:true, message:'User not found against the email.'})
            } else {
                console.error('Error initiating password reset:', error.message);
                setLoading(false)
            }
        }

        // setLoading(true)
        // setTimeout(() => {
        //     setLoading(false)
        //     setActiveStep((prevStep) => {
        //         return prevStep + 1;
        //     })

        // }, 3000)
    }

    const handleCodeChange = (name, value) => {
        setCode((prevState) => ({
            ...prevState,
            [name]: value
        }))

        // Move focus to the next input field
        const fieldIndex = parseInt(name.replace('digit', ''), 10);
        if (value && fieldIndex < Object.keys(code).length) {
            inputRefs.current[fieldIndex + 1].focus();
        }
    }

    // Function to handle key press (to handle backspace)
    const handleKeyPress = (fieldName, event) => {
        const fieldIndex = parseInt(fieldName.replace('digit', ''), 10);
        console.log("Field Index in key press: ", fieldIndex)
        if (event.key === 'Backspace' && fieldIndex > 1 && !code[fieldName]) {
            // Move focus to the previous input field if backspace is pressed on an empty input
            inputRefs.current[fieldIndex - 1].focus();
        }
    };

    const handlePasswordChange = (name, value) => {
        console.log("password fields : ", name, value)
        setPasswordFields((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleCodeSubmit = async () => {
        console.log("Code Obj : ", code)
        // const {digit1, digit2, digit3, digit4, digit5, digit6} = code
        const resetCode = Object.values(code).join("").toString();

        if (!resetCode) {
            setErrorSnackbar({ isOpen: true, message: "Kindly enter the code then submit." })
            return
        }
        if (String(resetCode).length !== 6) {
            setErrorSnackbar({ isOpen: true, message: "Kindly enter the complete code then submit." })
            return
        }

        setResetCode(resetCode)
        console.log("Code : ", resetCode)
        try {
            await Auth.forgotPasswordSubmit(email, resetCode, "111");
            console.log('Code Verified');
            setActiveStep((prevStep) => prevStep + 1);
        } catch (error) {
            console.log('Unexpected error:', error);
            console.log('code error:', error.code);
            if (error.code === 'InvalidPasswordException') {
                setActiveStep((prevStep) => prevStep + 1);
            } else if (error.code === 'CodeMismatchException') {
                console.log('Code Not Verified');
                setErrorSnackbar({isOpen:true, message:'Kindly Provide valid verification code.'})
            } else {
                console.error('Unexpected error:', error);
            }
        }
    }
    const handlePasswordSubmit = async () => {
        console.log("Strength : ", passwordStrength)
        if (!passwordFields.newPassword || !passwordFields.confirmPassword) {
            setErrorSnackbar({ isOpen: true, message: "Kindly enter both new and confirm passwords." })
            return
        }
        if (passwordFields.newPassword !== passwordFields.confirmPassword) {
            setErrorSnackbar({ isOpen: true, message: "Kindly make sure both new and confirm passwords are same." })
            return
        }
        if (passwordStrength !== 1) {
            setErrorSnackbar({ isOpen: true, message: "Kindly make sure you've entered strong password" })
            return
        }
        setSuccessSnackbar({ isOpen: true, message: 'Password reset successfully...' })
        console.log("Password : ", passwordFields)
        try {
            await Auth.forgotPasswordSubmit(email, resetCode, passwordFields.confirmPassword);
            setActiveStep((prevStep) => prevStep + 1);
            console.log('Password reset successfully');
        } catch (error) {
            console.log('Error completing new password:');
            console.error("Error completing new password:", error);
        }
    }

    return (
        <div className={styles.forgot_password_container}>
            {activeStep === 0 &&
                <EmailComponent email={email} setEmail={setEmail} handleEmailSend={handleEmailSend} emailError={emailError} loading={loading} handleEmailChange={handleEmailChange} />
            }
            {activeStep === 1 &&
                <ResetCodeComponent email={email} handleCodeChange={handleCodeChange} handleCodeSubmit={handleCodeSubmit} code={code} inputRefs={inputRefs} handleKeyPress={handleKeyPress} loading={loading} />
            }
            {activeStep === 2 &&
                <PasswordResetComponent passwordFields={passwordFields} handlePasswordChange={handlePasswordChange} handlePasswordSubmit={handlePasswordSubmit} passwordStrength={passwordStrength} setPasswordStrength={setPasswordStrength} loading={loading} />
            }
            {activeStep === 3 &&
                <SuccessComponent loading={loading} />
            }
            <div className={styles.progress_bar_container}>
                <span style={{ backgroundColor: activeStep === 0 && "#F27340" }} className={styles.progress_bar}></span>
                <span style={{ backgroundColor: activeStep === 1 && "#F27340" }} className={styles.progress_bar}></span>
                <span style={{ backgroundColor: activeStep === 2 && "#F27340" }} className={styles.progress_bar}></span>
                <span style={{ backgroundColor: activeStep === 3 && "#F27340" }} className={styles.progress_bar}></span>

            </div>
            <ErrorSnackbar isErrorSnackbarOpen={errorSnackbar} setIsErrorSnackbarOpen={setErrorSnackbar} />
            <SuccessSnackbar isSnackbarOpen={successSnackbar} setIsSnackbarOpen={setSuccessSnackbar} />
        </div>
    )
}