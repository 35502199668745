// Sidebar.js
import React from 'react';
import { useState, useEffect } from 'react';
import styles from './ChatsSubMenubar.module.css';
import MarkChatReadOutlinedIcon from '@mui/icons-material/MarkChatReadOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import CallMissedOutgoingOutlinedIcon from '@mui/icons-material/CallMissedOutgoingOutlined';
import AlignHorizontalLeftOutlinedIcon from '@mui/icons-material/AlignHorizontalLeftOutlined';
import { Link, useParams } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SensorsIcon from '@mui/icons-material/Sensors';
import * as api from '../../Service/API.js';
import { useAuth } from '../../Service/AuthContext.js';
import { useConnectedUsersContext } from '../../Service/ConnectedUsersContext.js';
import HistoryIcon from '@mui/icons-material/History';

const ChatsSubMenubar = ({ page }) => {
  const { chatType } = useParams();
  const { widgetID, userAttributes } = useAuth();
  const [adminList, setAdminList] = useState([])
  const { connectedUsers, disconnectedUsers } = useConnectedUsersContext();


  const activeChatUsers = connectedUsers.filter(user => {
    return user.isActiveChat === true && user.adminAssignedUsername && user.adminAssignedUsername === userAttributes.email;
  });
  const closedChatUsers = disconnectedUsers.filter(user => user.isChatClosed === true);
  const allChatUsers = disconnectedUsers.filter(user => user.isConversation === true);
  const offlineChatUsers = disconnectedUsers.filter(user => user.isConversation === true && user.AdminUsername === "Bot");

  const activeChatUsersLength = activeChatUsers.length;
  const closedChatUsersLength = closedChatUsers.length;
  const allChatUsersLength = allChatUsers.length;
  const offlineChatUsersLength = offlineChatUsers.length


  const getOptionStyle = (type) => {
    const baseStyle = {
      textDecoration: 'none',
      color: 'inherit',
    };

    if (chatType === type) {
      return {
        ...baseStyle,
        backgroundColor: '#fceae5', // Set your desired background color
        color: '#f37440', // Set your desired text color
        borderLeft: "2px solid #f37440"
      };
    }

    return baseStyle;
  };



  useEffect(() => {
    const fetchAdmins = async () => {
      const adminList = await api.fetchAdminList(widgetID);
      console.log("adminList ====>", adminList)
      setAdminList(adminList);
    };

    fetchAdmins()
  }, []);




  return (
    <div className={styles.sidebar}>
      <div>

        <div className={styles.title}>My Chats</div>
        <div className={styles.subtitle}>Conversation</div>
        <ul className={styles.options}>

          {page === "ActiveChat" && (
            <Link to="/activechat" style={{ backgroundColor: '#fceae5', color: '#f37440', borderLeft: "2px solid #f37440", textDecoration: 'none', color: 'inherit' }} className={styles.option}>
              <div style={{ color: "#f37440" }} className={styles.optionContent}>
                <SensorsIcon sx={{ fontSize: 18, color: "#f37440" }} />
                Active
              </div>
              <span style={{ color: "#f37440" }} className={styles.badge}>{activeChatUsersLength}</span>
            </Link>
          )}

          {page !== "ActiveChat" && (
            <Link to="/history/allchats" style={getOptionStyle('allchats')} className={styles.option}>
              <div className={styles.optionContent}>
                <AlignHorizontalLeftOutlinedIcon sx={{ fontSize: 18, color: chatType === "" ? "#525252" : "inherit" }} />
                All
              </div>
              <span style={{ color: chatType === "" ? "orange" : "inherit" }} className={styles.badge}>{allChatUsersLength}</span>
            </Link>
          )}




          <Link to="/history/closedchats" style={getOptionStyle('closedchats')} className={styles.option}>
            <div className={styles.optionContent}>
              <MarkChatReadOutlinedIcon sx={{ fontSize: 18, color: chatType === "" ? "#525252" : "inherit" }} />
              Closed
            </div>
            <span style={{ color: chatType === "" ? "orange" : "inherit" }} className={styles.badge}>{closedChatUsersLength}</span>
          </Link>


          {page !== "ActiveChat" && (
            <>
              {/* <Link to="/history/missedchats" style={getOptionStyle('missedchats')} className={styles.option}>
                <div className={styles.optionContent}>
                  <CallMissedOutgoingOutlinedIcon sx={{ fontSize: 18, color: chatType === "" ? "#525252" : "inherit" }} />
                  Missed
                </div>
                <span style={{ color: chatType === "" ? "orange" : "inherit" }} className={styles.badge}>0</span>
              </Link> */}
              <Link to="/history/offlinechats" style={getOptionStyle('offlinechats')} className={styles.option}>
                <div className={styles.optionContent}>
                  <HistoryIcon sx={{ fontSize: 18, color: chatType === "" ? "#525252" : "inherit" }} />
                  Offline
                </div>
                <span style={{ color: chatType === "" ? "orange" : "inherit" }} className={styles.badge}>{offlineChatUsersLength}</span>
              </Link>
              <Link to="/history/archivedchats" style={getOptionStyle('archivedchats')} className={styles.option}>
                <div className={styles.optionContent}>
                  <ArchiveOutlinedIcon sx={{ fontSize: 18, color: chatType === "" ? "#525252" : "inherit" }} />
                  Archived
                </div>
                <span style={{ color: chatType === "" ? "orange" : "inherit" }} className={styles.badge}>0</span>
              </Link>
             
            </>

          )}
        </ul>
      </div>

      {page === "ActiveChat" && (
        <div style={{ marginTop: "30px" }}>
          <div className={styles.subtitle}>Your Team</div>
          <div className={styles.teamList}>
            {adminList
              .sort((a, b) => (a.isOnline ? -1 : 1)) // Sort online admins first
              .map((admin, index) => (
                <div className={styles.teamMember} key={index}>
                  <div className={styles.avatarContainer}>
                    <Avatar sx={{ width: 26, height: 26, fontSize: 14, background:  admin.isOnline ? "#2a3e48" : "#c5c5c5"}}>
                      {admin.fullName?.charAt(0).toUpperCase()}
                    </Avatar>
                    {/* Add your online dot styling here */}
                    <div style={{ backgroundColor: admin.isOnline ? "rgb(0, 205, 0)" : "rgb(255, 163, 0)" }} className={styles.onlineDot} />
                  </div>
                  <div className={styles.memberInfo}>
                    <div className={styles.memberDetails}>
                      <span className={styles.memberName}>{admin.fullName}</span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}


    </div>

  );
};

export default ChatsSubMenubar;
