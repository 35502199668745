
import React, { useEffect, useState } from 'react';
import socket from '../../socketjs';
import { BsClock } from "react-icons/bs";
import { IoIosLink } from "react-icons/io";
import { FiMessageSquare } from "react-icons/fi";
import ReactCountryFlag from 'react-country-flag';
import styles from './BottomNotification.module.css'


const BottomNotification = ({ username, visitlink, content, index, onClose, onReplyClick, sessionID, country_code2}) => {

    const [timer, setTimer] = useState(60);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer((prevTimer) => Math.max(prevTimer - 1, 0));
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (timer === 0) {
            onClose(index);
        }
    }, [timer, onClose, index]);

    const timerStyle = {
        textAlign: 'right',
        color: timer <= 10 ? 'red' : 'rgb(63 83 105)',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",

    };


    return (

        <div className={styles.boxStyle}>
            {/* Row 1 */}
            <div className={styles.rowStyle} >
                <span className={styles.avatarStyle}>V</span>
                {username}
                <ReactCountryFlag
                    style={{ width: '20px', height: '20px', }}
                    countryCode={country_code2} svg />
            </div>

            {/* Row 2 */}
            <div className={styles.rowStyle}>
                <FiMessageSquare color='grey' />
                <a>{content.length > 40 ? content.substring(0, 40) + '...' : content}</a>
            </div>


            {/* Row 3 */}
            <div className={styles.rowStyle}>
                <IoIosLink color='grey' />
                <a href={visitlink} style={{ color: '#3498db' }}> {visitlink}</a>
            </div>

            {/* Row 4 c*/}
            <div className={styles.rowStyle} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={timerStyle}>
                    <BsClock color='grey' />
                    {`${Math.floor(timer / 60)
                        .toString()
                        .padStart(2, '0')}:${(timer % 60).toString().padStart(2, '0')}`}
                </div>
                <div className={styles.buttonContainer}>
                    <button onClick={onClose} className={styles.buttonStyleIgnore}>Ignore</button>
                    <button onClick={onReplyClick} className={styles.buttonStyleAccept}>Accept</button>
                </div>


            </div>
        </div>

    );
};

export default BottomNotification;
