import React, { createContext, useState, useContext } from 'react';

const GlobalVariablesContext = createContext();

export const GlobalVariablesProvider = ({ children }) => {
  const [showIndicatorNewUser, setShowIndicatorNewUser] = useState(false);
  const [showIndicatorNewMessage, setShowIndicatorNewMessage] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [onlineStatus, setOnlineStatus] = useState(true);
  const [cardList, setCardList] = useState([]);
  const [startingCard, setStartingCard] = useState([]);

  const toggleIndicatorNewUser = () => {
    setShowIndicatorNewUser(false);
  };

  const toggleIndicatorNewMessage = () => {
    setShowIndicatorNewMessage(false);
  };

  const setGlobalProfilePicture = (imageUrl) => {
    setProfilePicture(imageUrl);
  };

  const updateCardList = (newCardList) => {
    setCardList(newCardList);
  };

  const updateStartingCardList = (newCardList) => {
    setStartingCard(newCardList);
  };

  return (
    <GlobalVariablesContext.Provider value={{
      toggleIndicatorNewUser,
      toggleIndicatorNewMessage,
      showIndicatorNewUser,
      setShowIndicatorNewUser,
      showIndicatorNewMessage,
      setShowIndicatorNewMessage,
      setGlobalProfilePicture,
      profilePicture,
      setOnlineStatus,
      onlineStatus,
      startingCard,
      updateStartingCardList,
      cardList,
      updateCardList
    }}>
      {children}
    </GlobalVariablesContext.Provider>
  );
};

export const useGlobalVariablesContext = () => {
  return useContext(GlobalVariablesContext);
};
