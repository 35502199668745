import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import socket from '../../socketjs.js';
import axios from 'axios';

import ConversationBox from './Conversation_components/ConversationBox/ConversationBox.js';
import RightInfoSidebar from './Conversation_components/RightInfoSidebar/RightInfoSidebar.js';
import { useAuth } from '../../Service/AuthContext.js';




const Conversation = (props) => {

  const [messageContent, setMessageContent] = useState('');
  const [messageSend, setMessageSend] = useState(false);
  const [userData, setuserData] = useState([])
  const [Isconversation, setIsconversation] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [SelectedPreview, setSelectedPreview] = useState(null);
  const [selectedPdfPreview, setSelectedPdfPreview] = useState(false)
  const [selectedPdfUrl, setSelectedPdfUrl] = useState("")
  const [selectedFileName, setSelectedFileName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [IsLoadingSidebar, setIsLoadingSidebar] = useState(true);
  const [editedUsername, setEditedUsername] = useState('');
  const [editedEmail, setEditedEmail] = useState('');
  const [editedContact, setEditedContact] = useState('');
  const [messages, setMessages] = useState([]);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const messageEndRef = useRef(null);
  const [edt, setEdt] = useState(false);
  const { widgetID } = useAuth();
  const [chatState, setChatState] = useState({
    isChatEnded: false,
    description: "Chat is ongoing",
  });

  console.log("Selected Preview Url conversation : ", SelectedPreview)

  var currentDate = new Date();
  var timestamp2 = currentDate.getTime();
  let ref;
  let authToken = "ComcatChatbotApi123$"


  const handleUsernameChange = (sessionID, newUsername) => {
    const updatedUsers = userData.map((user) =>
      user.sessionID === sessionID ? { ...user, username: newUsername } : user
    );

    setuserData(updatedUsers);
    setEditedUsername(newUsername)
  };

  const handleEmailChange = (sessionID, newEmail) => {

    const updatedUsers = userData.map((user) =>
      user.sessionID === sessionID ? { ...user, email: newEmail } : user
    );
    // Set the updated users array
    setuserData(updatedUsers);
    setEditedEmail(newEmail);
  };

  const handleContactChange = (sessionID, newEmail) => {

    const updatedUsers = userData.map((user) =>
      user.sessionID === sessionID ? { ...user, ContactNumber: newEmail } : user
    );
    // Set the updated users array
    setuserData(updatedUsers);
    setEditedContact(newEmail);
  };
  function scrollToBottom() {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  const updateUsername = (current_username, current_email, contactNumber) => {

    if (current_email && !current_email.includes("N/A")) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = emailPattern.test(current_email);
      setIsValidEmail(isValid);
      if (!isValid) {
        return
      }
    }

    const pass = editedUsername

    const newUsername = editedUsername || current_username;
    const newEmail = editedEmail || current_email;
    const newContactNumber = editedContact || contactNumber

    if ((editedUsername === '') && (editedEmail === '') && (editedContact === '')) {

    } else {
      socket.emit('update-username',widgetID, newUsername, props.selectedUser.sessionID, newEmail, newContactNumber,current_username, pass);
      setEditedUsername('')
      setEditedEmail('')
      setEditedContact('')
    }

    // Disable the edit mode after updating the username
    setEdt(false);
  };

  const fetchUserData = async (sessionID, setUserData, setIsConversation, setIsLoadingSidebar) => {
    try {
      const config = {
        headers: {
          Authorization: authToken, // Set the authorization token here
        },
      };
  
      const response = await axios.get(`https://prod.api.comcat.ai/users/${sessionID}`, config);
      setUserData(response.data);
      setIsConversation(response.data[0].isConversation);
      setIsLoadingSidebar(false);


  
      setChatState((prevState) => ({
        ...prevState,
        isChatEnded: response.data[0].isChatClosed,
      }));
    } catch (error) {
      throw new Error(error.response.data);
    }
  };

  const getmsg = useMemo(() => async (conversationId) => {
    try {
      const response = await axios.get(`https://prod.api.comcat.ai/messages/${conversationId}`,
      {
        headers: {
          Authorization: authToken, // Pass the authorization token in the headers
        },
      }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data);
    }
  }, []);


  const fetchMessages = async () => {
    let botMessages = [];

    try {
      const botResponse = await getmsg(`bot_${props.selectedUser.sessionID}`);
      botMessages = botResponse.messages;
    } catch (error) {

    }

    try {
      const userResponse = await getmsg(`${widgetID}_${props.selectedUser.sessionID}`);
      if (botMessages.length > 0) {
        const messagesList = userResponse ? [...botMessages, ...userResponse.messages] : botMessages;
        messagesList.sort((a, b) => {
          return new Date(a.timestamp) - new Date(b.timestamp);
        });
        setMessages(messagesList);
      } else if (userResponse) {
        setMessages(userResponse.messages);
      } else {
        setMessages([]);
      }
    } catch (error) {
      console.error('Error fetching user messages:', error);
      setMessages([]);
    } finally {
      setIsLoading(false);
    }
  };


  const fetchDataAndUpdateState = (sessionID) => {
    fetchUserData(sessionID, setuserData, setIsconversation, setIsLoadingSidebar);
  };

  useEffect(() => {
    setMessages([]);
    setIsLoading(true)
    fetchDataAndUpdateState(props.selectedUser.sessionID);
    fetchMessages();

  }, [props.selectedUser, props.username]);

  useEffect(() => {
    const handleChangedUrl = ({ from, savedUrlData }) => {
      if (from === props.selectedUser.sessionID) {
        setIsLoadingSidebar(true);
        fetchDataAndUpdateState(props.selectedUser.sessionID);
      }
    };

    socket.on('ChangedUrl', handleChangedUrl);

    return () => {
      socket.off('ChangedUrl', handleChangedUrl);
    };
  }, [props.selectedUser]);

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 500); // 2000 milliseconds (2 seconds)
  }, [messages]);

  const onMessage = (e, content) => {
    if (e) {
      e.preventDefault();
    }

    setIsconversation(true);
    setMessageContent('');

    const { selectedUser, username, CurrentusersessionID, CurrentusersocketID, CurrentuserAuth } = props;
    const hasFile = selectedFile !== null;

    if (!content.trim() && !hasFile) {
      return;
    }

    if (ref && ref.target) {
      ref.target.value = '';
    }

    const emitMessage = (fileData) => {


      socket.emit('private message to client', {
        content,
        to: selectedUser.userID,
        to_username: selectedUser.username,
        userName: username,
        to_sessionID: selectedUser.sessionID,
        from: CurrentusersessionID,
        from_socketID: CurrentusersocketID,
        from_Username: CurrentuserAuth,
        timestamp: timestamp2,
        fileData: fileData,
        widgetID: widgetID,
      });
    };

    const updateMessages = (fileData) => {


      setMessages((messages) => [
        ...messages,
        {
          toUser: selectedUser.username,
          content,
          from_Usergroup: "admin",
          timestamp: timestamp2,
          fileDataLive: fileData,
        },
      ]);
    };

    if (selectedUser) {
      if (hasFile) {
        const reader = new FileReader();

        reader.onload = () => {
          const arrayBuffer = reader.result;
          const fileType = selectedFile.type; // Get the file type
          const fileName = selectedFile.name; // Get the file name
          const fileSizeMB = (selectedFile.size / (1024 * 1024)).toFixed(2); // Get the file size in MB
          const previewUrl = selectedPdfUrl || SelectedPreview

          const fileData = {
            arrayBuffer,
            fileType,
            fileName,
            fileSizeMB,
            previewUrl,
          }; // Create an object with ArrayBuffer content, file type, file name, and file size

          emitMessage(fileData);
          updateMessages(fileData);
        };

        reader.readAsArrayBuffer(selectedFile);
      } else {
        emitMessage(null);
        updateMessages(null);
      }

      setSelectedFile(null);
      setSelectedPreview(null);
      setSelectedPdfPreview(false)
      setSelectedFileName('');
    }

  };

  const handleFileChange = (e) => {

    const file = e.target.files[0];
    if (file.type.startsWith('application/pdf')) {
      console.log("in df file...", file)
      setSelectedPdfUrl(URL.createObjectURL(file))
      console.log(".....pdf local link", URL.createObjectURL(file))

      setSelectedFile(file);
      setSelectedPdfPreview(true)
      setSelectedFileName(file.name)
      e.target.value = null;
    } else if (e.target.files.length !== 0) {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;

        image.onload = () => {
          const canvas = document.createElement('canvas');
          const MAX_WIDTH = 800; // Set your desired maximum width
          const MAX_HEIGHT = 600; // Set your desired maximum height
          let width = image.width;
          let height = image.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0, width, height);

          canvas.toBlob((blob) => {
            const compressedFile = new File([blob], file.name, {
              type: file.type,
              lastModified: Date.now(),
            });

            setSelectedFile(compressedFile);
            setSelectedFileName(compressedFile.name);
          }, file.type, 0.7);


          setSelectedPreview(URL.createObjectURL(file));

          URL.revokeObjectURL(URL.createObjectURL(file));

          // Clear the file input value to allow re-selecting the same file
          e.target.value = null;
        };
      };

      reader.readAsDataURL(file);
    }
  };


  const handleIconClick = (e) => {
    
    setSelectedFile(null);
    setSelectedPreview(null);
    // setSelectedFileName('');
    setSelectedPdfPreview(false)
  };

  const updateMessages = (newMessages) => {
    if (newMessages.length > 0) {
      setMessages(prevMessages => [...prevMessages, ...newMessages]);
      setIsconversation(true);
    }
  };

  useEffect(() => {
    const handlePrivateMessage = ({ content, from, timestamp, fileData, from_Usergroup }) => {
      const userMessages = props.connectedUsers
        .filter(user => user.sessionID === from && user.UserGroup !== "admin")
        .map(user => ({
          content,
          timestamp,
          fileData,
          from,
          fromUser: user.username,
          from_Usergroup: from_Usergroup,
        }));


      setMessageSend(true);
      console.log("messgae recieved")
      updateMessages(userMessages);
    };

    // Attach the event listener
    socket.on('private message', handlePrivateMessage);

    // Clean up the event listener when the component is unmounted
    return () => {
      socket.off('private message', handlePrivateMessage);
    };
  }, [socket, updateMessages]);



  useEffect(() => {
    const handleSelfMessage = ({ content, from, to_sessionID, from_Username, from_Usergroup, timestamp, fileData, from_socketID }) => {

      if (props.CurrentusersocketID !== from_socketID && props.selectedUser.sessionID === to_sessionID) {
        const adminMessage = {
          content,
          timestamp,
          fileData: fileData,
          from_Usergroup: from_Usergroup,
          from_Username: from_Username,
        }
        updateMessages([adminMessage]); // Pass the message as an array
      }
    };

    socket.on("self_message_to_all_admins", handleSelfMessage);

    return () => {
      socket.off("self_message_to_all_admins", handleSelfMessage);
    };
  }, [socket, props.CurrentusersocketID, props.selectedUser.sessionID, updateMessages]);


  return (
    <>
      <div style={{ display: "flex" }}>

        {/* Conversation Box */}

        <ConversationBox
          isLoading={isLoading}
          messages={messages}
          username={props.selectedUser.username}
          currentAuthUsername={props.CurrentuserAuth.username}
          selectedUserSessionId={props.selectedUser.sessionID}
          messageSend={messageSend}
          setMessageSend={setMessageSend}
          onMessage={onMessage}
          handleFileChange={handleFileChange}
          selectedUserId={props.selectedUser.userID}
          SelectedPreview={SelectedPreview}
          selectedFileName={selectedFileName}
          handleIconClick={handleIconClick}
          messageEndRef={messageEndRef}
          props={props}
          Isconversation={Isconversation}
          messageContent={messageContent}
          setMessageContent={setMessageContent}
          setChatState={setChatState}
          chatState={chatState}
          selectedPdfPreview={selectedPdfPreview}
          selectedFile={selectedFile}
          selectedPdfUrl={selectedPdfUrl}
        />

        {/* Conversation Box */}

        {/* right info sidebar */}
        <RightInfoSidebar
          userData={userData}
          selectedUserSessionId={props.selectedUser.sessionID}
          IsLoadingSidebar={IsLoadingSidebar}
          handleUsernameChange={handleUsernameChange}
          updateUsername={updateUsername}
          edt={edt}
          setEdt={setEdt}
          isValidEmail={isValidEmail}
          handleEmailChange={handleEmailChange}
          handleContactChange={handleContactChange}
        />
        {/* right info sidebar */}
      </div>
    </>
  );

};

export default Conversation;