import React from 'react';
import { useState, useEffect } from 'react';
import ReactCountryFlag from 'react-country-flag';
// import '../../Styles/Online.css'; 
import socket from '../../../socketjs.js';
import { AiOutlineClockCircle } from 'react-icons/ai';
import B1 from '../../../Img/Image1.png'
import styles from "./history.module.css"
import { useUser } from '../../../Service/SelectedUserContext.js';
import { useAuth } from '../../../Service/AuthContext.js';
import { useParams } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Tooltip from "@mui/material/Tooltip";
import botIcon from '../../../Img/botIcon.png'
import { MdOutlineAttachment } from "react-icons/md";


const History = (props) => {
  let userList = props.connectedUsers;
  const pageSub = props.pageSub;
  const { selectedUser, setSelectedUser } = useUser();
  const { widgetID } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();

  useEffect(() => {

    if (userList.length !== 0) {
      setIsLoading(false);
    }
  }, [userList]);

  localStorage.setItem('HistoryUsers', JSON.stringify(userList));


  const userName_from_click = (selectedUser) => {
    const sessionID = selectedUser.sessionID
    selectedUser.page = 'history';
    setSelectedUser(selectedUser);
    props.selectUser(selectedUser);
    if (selectedUser.unreadCount && selectedUser.unreadCount !== "0") {
      socket.emit('messages read', sessionID, widgetID);
      const updatedUserList = userList.map(u => {
        if (u.sessionID === selectedUser.sessionID) {
          u.unreadCount = "0";
        }
        return u;
      });


      userList = updatedUserList
    }
  };

  useEffect(() => {
    console.log("This use Effect is running now")
    if (userList.length > 0) {

      if (params?.hasOwnProperty("sessionID")) {
        const userSelected = userList.find(user => user.sessionID === params["sessionID"]);
        userName_from_click(userSelected);
      } else {
        console.error("Session ID not found in params.");
      }
    }
  }, [params?.sessionID]);




  socket.on("user-info-to-admin", (editedUsername, sessionID) => {
    for (let user of userList) {
      if (user.sessionID === sessionID) {
        user.username = editedUsername;
        break;
      }
    }
  });

  let showUsers = userList
    .filter(user => {
      if (pageSub === "All Chats") {
        return user.isConversation === true;
      } else if (pageSub === "Closed Chats") {
        return user.isChatClosed === true;
      }
      else if (pageSub === "Missed Chats") {
        return user.isConversation === true && user.isChatMissed === true;
      }
      else if (pageSub === "Archived Chats") {
        return user.isConversation === true && user.isChatArchived === true;
      }
      else if (pageSub === "Offline Chats") {
        return user.isConversation === true && user.AdminUsername === "Bot";
      }
      // Add more conditions as needed
      return false; // Default case if none of the conditions match
    })
    .sort((userA, userB) => {

      const disconnectTimeA = new Date(userA.disconnectTime);
      const disconnectTimeB = new Date(userB.disconnectTime);
      return disconnectTimeB - disconnectTimeA; // Sort in descending order
    })
    .map(user => {
      const isSelected = selectedUser && selectedUser.username === user.username;

      const disconnectTime = new Date(user.disconnectTime);
      const currentTime = new Date();
      const timeDifference = currentTime - disconnectTime;
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      
      let timeAgo;
      
      if (daysDifference === 0) {
        // Show time only if the disconnect time is today
        timeAgo = disconnectTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).toLowerCase();
      } else if (daysDifference < 365) {
        // Show "day month, year" if the disconnect time is within the current year
        timeAgo = disconnectTime.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' });
      } else {
        // Show full date with year if the disconnect time is not within the current year
        timeAgo = disconnectTime.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' });
      }
      


      return (

        <div
          key={user._id}
          className={`${styles['main-sidebar-content-cnt']} ${isSelected ? styles.selected : styles.notSelected}`}
          onClick={() => {
            userName_from_click(user);
          }}
          ref={(ref) => {
            // Add a ref to the selected user's element
            if (isSelected) {
              ref && ref.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          }}
        >
          <div className={styles['sidebar-cnt-tp']}>
            <div className={styles['online-icon']}>
              {/* <div className={styles['outside-user']}>
                <Avatar sx={{ bgcolor: "#1d5177", width: "20px", height: "20px", fontSize: "10px", boxShadow: "1px 1px 7px 0 rgba(101, 101, 101, 0.2), 1px 1px 7px 0 rgba(100, 100, 100, 0.19)" }}>
                  {user.username.charAt(0).toUpperCase()}
                </Avatar>
              </div> */}
                 <ReactCountryFlag style={{
                width: '1em',
                height: '1em',
              }} countryCode={user.country_code2} svg />
              <span style={{ fontWeight: 500 }}>{user.username}</span>
            </div>

            <span style={{ gap:"8px",padding: '2px 8px', borderRadius: '20px', fontSize: '11px', fontWeight: "500", color: '#5e5e5ef2' }} className={styles['sidebar-top-right']}>
              {/* <AiOutlineClockCircle className={styles.clock} /> */}
              {timeAgo}
            </span>
          </div>
          {/* <div className={styles['sidebar-cnt-tp']}>
            <div className={styles['online-icon']}>
              <ReactCountryFlag countryCode={user.country_code2} svg />
              <span className={styles['sidebar-cnt-dn-cntry']}>{user.country_name}</span>
            </div>
          </div> */}

          <div style={{ fontSize: '12px', marginTop: '3px', display: "flex", justifyContent: "space-between", }}>
            <span
              style={{
                // display: 'inline-block',
                fontSize: '13px',
                fontWeight: '500',
                paddingRight: "22px",
                color: 'black',
                display: 'flex',
                alignItems: 'normal',
                // border: '1px solid #efefef' 
              }}
              className={styles['sidebar-top-right']}
            >
              <span style={{ color: '#4db8ff', fontWeight: 500,marginRight:"3px"}}> {user.lastMessage?.[0]?.self === false ? "You:" : null}</span>
              {user.lastMessage?.[0]?.message ? (
                <span style={{ color: '#575757', fontWeight: 400,marginRight:"3px"}}>
                  {user.lastMessage[0].message.length > 20
                    ? user.lastMessage[0].message.slice(0, 45) + '...'
                    : user.lastMessage[0].message}
                </span>
              ) : user.lastMessage?.[0]?.file ? (
                user.lastMessage[0].fileType ? (
                  user.lastMessage[0].fileType.toLowerCase().includes('pdf') ? (
                    <span style={{ color: '#575757', fontWeight: 400,marginRight:"3px",fontStyle:"italic" }}>   <MdOutlineAttachment/> Attachment has been sent as a PDF file</span>
                  ) : user.lastMessage[0].fileType.toLowerCase().includes('image') ? (
                    <span style={{ color: '#575757', fontWeight: 400,marginRight:"3px",fontStyle:"italic"  }}> <MdOutlineAttachment/> Attachment has been sent as an image</span>
                  ) : (
                    <span style={{ color: '#575757', fontWeight: 400,marginRight:"3px",fontStyle:"italic" }}> <MdOutlineAttachment/> Attachment has been sent</span>
                  )
                ) : (
                  <span style={{ color: '#2e2e2e', fontWeight: 400,fontStyle:"italic"  }}>Attachment has been sent</span>
                )
              ) : null}

            </span>

            <div style={{ fontSize: '12px', marginTop: '8px', display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Tooltip title={user?.AdminUsername}>
                {user?.AdminUsername?.toLowerCase() === "bot" ? (
                  <img
                    src={botIcon} // Replace with the actual path to your bot icon image
                    alt="Bot"
                    style={{ width: "20px", height: "20px", objectFit: "cover" }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      bgcolor: "#798f9c",
                      width: "20px",
                      height: "20px",
                      fontSize: "10px",
                      
                    }}
                  >
                    {user?.AdminUsername?.charAt(0).toUpperCase()}
                  </Avatar>
                )}
              </Tooltip>
              {(user.unreadCount && user.unreadCount !== "0") && (
                <div className={styles['unread-count']}>
                  {user.unreadCount}
                </div>
              )}
            </div>

          </div>


        </div>

      );
    });
  return (
    <div>
      {userList.length > 0 ? (
        isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '55vh', marginTop: "25px" }}>
            <div className={styles.loader}></div>
          </div>
        ) : (
          // Display the user list once loading is done
          <div style={{ marginBottom: "15%" }}>
            <div>{showUsers}</div>
          </div>
        )
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '14px',
            alignItems: 'center',
            height: '63vh', // Adjust this to control the height of the container
          }}
        >
          No chats available
        </div>
      )}
    </div>

  );
};

export default History;
