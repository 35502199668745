import React, { useState, useEffect } from "react";
import { Auth } from 'aws-amplify';
import "./login.css"
import { alpha, styled } from '@mui/material/styles';
import facebook from '../../Img/facebook.png';
import Particles from '../../Img/Particles.png';
import modalvideo from '../../Img/modalvideo.mp4';
import Button from '@mui/material/Button'
import google from '../../Img/google.png';
import macbook from '../../Img/macbook.png';
import { BiHide, BiShow } from 'react-icons/bi';
import styles from "./login.module.css"
import { TailSpin } from 'react-loader-spinner'
import { TextField, InputAdornment, Checkbox } from '@mui/material';
import Typewriter from 'typewriter-effect';
import { BrowserRouter as Router, Route, Link, Navigate } from 'react-router-dom';
import comcatText from '../../Img/comcatText.png';
import comcatLogoDark from '../../Img/comcatLogoDark.png';
import { useNavigate } from 'react-router-dom';

const CssTextField = styled(TextField)({
  // '& label.Mui-focused': {
  //   color: '#f37440',
  // },x
  // '& .MuiInput-underline:after': {
  //   borderBottomColor: '#f37440',
  // },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#DBDBDB',
    },

    "& input::placeholder": {
      fontSize: "13px"
    }
    // '&:hover fieldset': {
    //   borderColor: 'yellow',
    // },
    // '&.Mui-focused fieldset': {
    //   borderColor: '#f37440',
    // },
  },
});

const Login = (props) => {
  const navigate = useNavigate()
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false)
  const [user, setUser] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSignin, setLoadingSignin] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [buttonPressed, setButtonPressed] = useState(false);
  const fullText = "Discover the future of customer";
  const fullText2 = "Stampa ChatBot for every business type and";

  const handleSignIn = async () => {
    try {
      const googleUser = await Auth.federatedSignIn({ provider: 'Google' });
      console.log('User signed in',googleUser);
    } catch (error) {
      console.error('Error signing in:', error);
    }

    // setLoading(true);

    // try {
    //   const user =  await Auth.federatedSignIn({ provider: 'Google' });

    //   console.log("user", user)
   
    //   const attributes = user.attributes;
    //   props.submit(username, attributes);

    // } catch (error) {
    //   console.error('Error signing in:', error);
    //   setErrorMsg(error.message || 'An error occurred while signing in.');
    // } finally {
    //   setLoading(false);
    // }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMsg('');
    setLoading(true);

    try {
      const user = await Auth.signIn(username, password);


      console.log("user", user)
      console.log("user", user.challengeName)


      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {

        console.log("Password before navigation in login : ", password)
        navigate('/ForceReset', { state: { password,username } });


        // console.log("NEW_PASSWORD_REQUIRED")
        // const newPassword = password
        // const updatedUser = await Auth.completeNewPassword(user, newPassword);
        // console.log("updatedUser", updatedUser)
        // if (updatedUser) {

        //   const user = await Auth.signIn(username, newPassword);
        //   const attributes = user.attributes;
        //   props.submit(username, attributes);
        // }
      } else {
        const attributes = user.attributes;
        props.submit(username, attributes);
      }


    } catch (error) {
      console.error('Error signing in:', error);
      setErrorMsg(error.message || 'An error occurred while signing in.');
    } finally {
      setLoading(false);
    }
  };




  const handlePasswordReset = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    try {
      await Auth.completeNewPassword(user, newPassword);
      await Auth.signIn(username, newPassword);

      props.submit(username);
    } catch (error) {
      console.log('Error resetting password:', error);
    }
  };

  const handleCheckbox = () => {
    setIsChecked(!isChecked)
  }

  const onSignin = () => {
    setLoadingSignin(true);

    setTimeout(() => {
      setLoadingSignin(false);
      setButtonPressed(true);
    }, 1000);

  };

  const handleShow = () => {
    setShow(!show);
  }

  const divStyle = {
    background: 'linear-gradient(0deg, rgba(8, 58, 94, 0.50) 0%, rgba(8, 58, 94, 0.50) 100%), linear-gradient(90deg, #5E61EE 0.2%, #955DF4 100%)',
  };
  return (
    <div className={styles['login-main']}>
      {(!buttonPressed && !loadingSignin) && (

        <div className={styles['login-right']}>
          <div style={{ paddingTop: "6rem" }} className={styles.login_left_content}>
            <div className={styles.logo_container}>
              <div style={{ height: "32px", maxWidth: 'max-content' }}>
                <img src={comcatLogoDark} alt="" className={styles.comcatLogo} style={{ width: "35px" }} />
              </div>
              <div style={{ height: "25px", maxWidth: 'max-content' }}>
                <img src={comcatText} alt="" style={{ width: "70px" }} />
              </div>
            </div>

            <div className={styles.main_content_container}>
              <div className={styles.top_content} style={{ width: "100%", textAlign: "center" }}>
                <h1 className={styles.welcome}>Welcome Back</h1>
                <p className={styles.p_tag}>Log into your comcat account</p>

                <button style={{ backgroundColor: loading ? "rgb(107 107 107)" : "" }} type='submit' disabled={loading} onClick={onSignin} className={styles.login_btn}>{loading ? <div className={styles["loader-login"]}></div> : "Log In"}</button>

                {/* <div className={styles['login-btn']}>
                  <Button onClick={onSignin} className={styles.login_btn} style={{ backgroundColor: loading ? "rgb(107 107 107)" : "" }} type='submit' disabled={loading} variant="contained">

                    {loading ? <div className={styles["loader-login"]}></div> : "Log In"}
                  </Button>
                </div> */}
              </div>
              <div className={styles.top_content}>
                <p className={styles.continue_with} >Or continue with</p>
                <div onClick={handleSignIn} className={styles['mian-social-login-card']}>
                  <img src={google} alt='google' style={{ width: "24px", height: "25px" }} />
                  <span>Continue with Google</span>
                </div>
                <div onClick={() => Auth.federatedSignIn()} className={styles['mian-social-login-card']}>
                  <img src={facebook} alt='google' style={{ width: "26px", height: "26px" }} />
                  <span>Continue With facebook</span>
                </div>

              </div>
              <div className={styles['no-account']}>
                <p>Don't have an account? <Link to="/signup" className={styles['signup-link']}>Let's get started</Link></p>

              </div>
            </div>


          </div>
        </div>



      )}

      {loadingSignin && (
        <div className={styles['login-right']}>
          <div className={styles["loader-login-2"]}></div>
        </div>
      )}


      {/* will show after user pressed login */}
      {buttonPressed && !loadingSignin && (
        <div className={styles['login-right']}>
          <div style={{ paddingTop: "2rem" }} className={styles.login_left_content}>
            <div className={styles.logo_container}>
              <div style={{ height: "32px", maxWidth: 'max-content' }}>
                <img src={comcatLogoDark} alt="" className={styles.comcatLogo} style={{ width: "35px" }} />
              </div>
              <div style={{ height: "25px", maxWidth: 'max-content' }}>
                <img src={comcatText} alt="" style={{ width: "70px" }} />
              </div>
            </div>

            <div className={styles.main_content_container}>
              <div className={styles.top_content} style={{ width: "100%", textAlign: "center" }}>
                <h1 className={styles.welcome}>Welcome Back</h1>
                <p className={styles.p_tag}>Log into your comcat account</p>

                {/* login form */}

                {buttonPressed && !loadingSignin && (
                  <div style={{ paddingTop: "20px", width: "340px" }}>

                    <form onSubmit={handleSubmit}>
                      <div className={styles["input-field"]}>

                        <CssTextField

                          variant="outlined"
                          fullWidth
                          FormHelperTextProps={{ style: { color: '#d32f2f', marginLeft: '0px', } }}
                          placeholder="Email"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          required

                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                  <path fillRule="evenodd" clipRule="evenodd" d="M4.99984 3.33331H14.9998C16.8408 3.33331 18.3332 4.8257 18.3332 6.66665V14.1666C18.3332 16.0076 16.8408 17.5 14.9998 17.5H4.99984C3.15889 17.5 1.6665 16.0076 1.6665 14.1666V6.66665C1.6665 4.8257 3.15889 3.33331 4.99984 3.33331ZM11.1665 11.2916L16.4498 7.37498C16.6856 7.20164 16.7377 6.87067 16.5665 6.63331C16.4849 6.51999 16.3609 6.44452 16.2227 6.42411C16.0846 6.40371 15.9441 6.44009 15.8332 6.52498L10.4915 10.4166C10.3578 10.5567 10.1726 10.636 9.979 10.636C9.78537 10.636 9.60017 10.5567 9.4665 10.4166L4.1665 6.52498C4.05615 6.44276 3.91756 6.40797 3.78146 6.4283C3.64536 6.44864 3.523 6.52243 3.4415 6.63331C3.26852 6.86851 3.31681 7.19908 3.54984 7.37498L8.79984 11.25C9.12652 11.5599 9.55792 11.7354 10.0082 11.7416C10.4369 11.7416 10.8501 11.581 11.1665 11.2916Z" fill="#083A5E" />
                                </svg>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div className={styles["input-field"]}>
                        <CssTextField
                          type={show ? 'text' : 'password'}
                          // placeholder='Enter password'
                          name='password'
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                          FormHelperTextProps={{ style: { color: '#d32f2f', marginLeft: '0px', } }}
                          fullWidth
                          autoComplete='new-password'
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                  <path fillRule="evenodd" clipRule="evenodd" d="M15.1721 6.52854V3.66854C15.1726 2.92138 14.869 2.20618 14.3313 1.68746C13.7935 1.16873 13.0679 0.891143 12.3212 0.918538H9.31456C8.5679 0.891143 7.84224 1.16873 7.3045 1.68746C6.76675 2.20618 6.46322 2.92138 6.46372 3.66854V6.52854C4.56731 7.20009 3.41384 9.12254 3.71372 11.1119L4.58456 16.401C5.00316 18.5274 6.89125 20.0441 9.05789 19.9944H12.6146C14.7812 20.0441 16.6693 18.5274 17.0879 16.401L17.9587 11.1119C18.2541 9.1125 17.0831 7.18641 15.1721 6.52854ZM11.7346 13.6877L11.9271 14.256C12.0115 14.5162 11.9674 14.8009 11.8082 15.0233C11.6491 15.2458 11.3938 15.3794 11.1204 15.3835H10.4421C10.1686 15.3794 9.91334 15.2458 9.75421 15.0233C9.59508 14.8009 9.55099 14.5162 9.63539 14.256L9.82789 13.6877C9.93168 13.3666 9.85069 13.0145 9.61706 12.771C9.09762 12.1539 9.14999 11.2387 9.73643 10.6849C10.3229 10.131 11.2396 10.131 11.826 10.6849C12.4125 11.2387 12.4648 12.1539 11.9454 12.771C11.7118 13.0145 11.6308 13.3666 11.7346 13.6877ZM7.83872 6.21687H8.16872V6.22604H13.7971V3.66854C13.7624 2.8872 13.1027 2.28083 12.3212 2.31187H9.31456C8.53307 2.28083 7.87343 2.8872 7.83872 3.66854V6.21687Z" fill="#083A5E" />
                                </svg>
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <div className={styles['iconDivLock']} >
                                  {show ? (
                                    <BiHide onClick={handleShow} className={styles['eye-icon']} />
                                  ) : (
                                    <BiShow onClick={handleShow} className={styles['eye-icon']} />
                                  )}
                                </div>
                              </InputAdornment>
                            ),
                          }}

                        />


                      </div>
                      {errorMsg && (<span className={styles['wrong-password']}>You have entered an invalid username or password</span>)}
                      <div className={styles['forgot-password']}>
                        <div className={styles.remember_me}>
                          <input type="checkbox" name="remember" id="" onChange={handleCheckbox} checked={isChecked} style={{ width: "25px", height: "17px" }} />
                          <span>Remember me</span>
                        </div>
                        <span href="#" className={styles.forgot_password} onClick={() =>  navigate("/ForgotPassword")} style={{cursor:"pointer"}}>Forgot password?</span>
                      </div>

                      <div className={styles['login-btn']}>
                        <Button className={styles.login_btn} style={{ backgroundColor: loading ? "rgb(107 107 107)" : "" }} type='submit' disabled={loading} variant="contained">

                          {loading ? <div className={styles["loader-login"]}></div> : "Login"}
                        </Button>
                      </div>

                    </form>
                  </div>
                )}

                {/* login form end */}

              </div>
              <div className={styles.top_content}>
                <p className={styles.continue_with}>Or continue with</p>
                <div className={styles['mian-social-login-card']}>
                  <img src={google} alt='google' style={{ width: "24px", height: "25px" }} />
                  <span>Continue with Google</span>
                </div>
                <div className={styles['mian-social-login-card']}>
                  <img src={facebook} alt='google' style={{ width: "26px", height: "26px" }} />
                  <span>Continue With facebook</span>
                </div>

              </div>
              <div className={styles['no-account']}>
                <p>Don't have an account? <Link to="/signup" className={styles['signup-link']}>Create account</Link></p>
              </div>
            </div>


          </div>
        </div>
      )}

      <div className={styles['login-left']} style={divStyle}>



        <p style={{ fontSize: '28px', fontWeight: 'bold', display: "flex" }}>

          <Typewriter
            onInit={(typewriter) => {
              function typeText() {
                typewriter
                  .typeString(fullText)
                  .callFunction(() => {
                    console.log('String typed out: ' + fullText);
                  })
                  .pauseFor(2500)
                  .deleteAll()
                  .typeString(fullText2)
                  .callFunction(() => {
                    console.log('String typed out: ' + fullText2);
                  })
                  .pauseFor(2500) // Add a pause here before starting the loop again
                  .start(typeText); // Start the loop by calling the function again
              }

              typeText();
            }}
            options={{
              loop: true,
              delay: 50, // set this according to your requirements
              deleteSpeed: 30,
              wrapperClassName: "wrapperClassName"
            }}
          />

          <span style={{ color: '#f37440' }}> engagement</span>
        </p>
        <div className={styles["macbook-container"]}>
          <img src={macbook} alt="Macbook" className={styles["macbook-image"]} />
          <video width="820" height="620" autoPlay muted loop className={styles["video-player"]}>
            <source src={modalvideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

    </div>

  );
};

export default Login;
